import { useState } from "react";
import { CodeFormProps } from "../../CodeFormInfos";
import BackgroundFrequency from "../../../../imgs/frequencies.png";
import "../../../../styles/audio-player.scss";
import { StoryCodes, StoryConstants } from "../../../../shared/StoryConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/ReduxStore";
import audioBibli from "../../../../audio/paris_biblisquare.mp3";
import { Link } from "react-router-dom";
import { OneCodeButton } from "../../elements/OneCodeButton";
import FrequencyPlayer from "../frequencies_common/FrequencyPlayer";

// Cinq Par Onze STOP Seize et Treize STOP 14 deux fois STOP Je répète

export function CodeFrequenciesParis(p: CodeFormProps) {
  const maxValue = 99999;
  const [currentCodes, setCurrentCodes] = useState<number[]>([0, 0, 0, 0, 0]);
  const [currentCode, setCurrentCode] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false);

  const storyStatus = useSelector((state: RootState) => state.status.status);
  const [codeSent, setCodeSent] = useState(false);
  const stepIndex = storyStatus?.step?.index;

  if (
    !codeSent &&
    stepIndex &&
    stepIndex > StoryConstants.Steps.Paris.RECHERCHE_FREQUENCE
  ) {
    var nextCodes = [6, 1, 1, 0, 1];
    setCurrentCodes(nextCodes);
    const codeToCheck = nextCodes.reduce((p, curr) => p + curr, "");
    setCurrentCode(Number(codeToCheck));
    setCodeSent(true);
  }

  function setCodeIdx(idx: number, diff: number) {
    const value = currentCodes[idx] + diff;

    var realValue = value < 0 ? 9 : value > 9 ? 0 : value;
    var nextCodes = [...currentCodes];
    nextCodes[idx] = realValue;
    setCurrentCodes(nextCodes);
    const codeToCheck = nextCodes.reduce((p, curr) => p + curr, "");
    setCurrentCode(Number(codeToCheck));
    setPlaying(false);
  }

  const audioInfos = [
    {
      value: StoryCodes.PARIS_FREQ,
      path: audioBibli,
      transcription:
        "[Bruits de rue] Oui j'ai pas pris Villette, je continue. A gauche rue Mel... ok ! La suivante. Je vous préviens quand j'arrive au square.",
      audioUrl: audioBibli,
    },
  ];

  function checkIfSendingCode() {
    if (!codeSent) {
      if (
        stepIndex === StoryConstants.Steps.Paris.RECHERCHE_FREQUENCE &&
        currentCode === audioInfos[0].value
      ) {
        setCodeSent(true);
        setPlaying(false);
        p.checkCode(currentCode.toString());
      }
    }
  }

  const currentAudioInfos = audioInfos.find((x) => x.value === currentCode);

  return (
    <>
      <div
        className="bottom-part"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-1rem",
          marginBottom: "2.2rem",
        }}
      >
        {stepIndex === StoryConstants.Steps.Paris.RECHERCHE_FREQUENCE ? (
          <Link
            className="button is-dark"
            to="/document/croquis_1"
            style={{ border: "1px solid #bbb" }}
          >
            Voir le croquis
          </Link>
        ) : (
          <div className="text-encart is-family-monospace">
            Fréquence :&nbsp;&nbsp;{audioInfos[0].value} kHz
          </div>
        )}
      </div>
      <div style={{ margin: "0 auto 1.5rem", width: "fit-content" }}>
        <div
          className="core-buttons inline-buttons code-app-field"
          style={{ marginBottom: "1.2rem", width: "fit-content" }}
        >
          <div className="core-buttons">
            {new Array(currentCodes.length).fill(null).map((_, i) => {
              return (
                <OneCodeButton
                  correct={p.correct}
                  setCode={(x) => setCodeIdx(i, x)}
                  value={currentCodes[i].toString()}
                  key={i}
                />
              );
            })}
          </div>
        </div>
        <button
          className="button size-fix is-pale is-family-monospace"
          onClick={(e) => {
            e.preventDefault();
            setPlaying(!playing);
          }}
          style={{ width: "100%" }}
        >
          {playing ? "Arrêter" : "Lancer"} Analyse
        </button>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FrequencyPlayer
          realAudioUrl={currentAudioInfos?.audioUrl}
          audioDescription={currentAudioInfos?.transcription}
          playing={playing}
          onLoopEnd={checkIfSendingCode}
        />
      </form>
    </>
  );
}
