import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ToulouseMessage7Frequences() {
  return (
    <BaseMessage title="Fréquences">
      <p>Vous êtes sur la bonne voie.</p>
      <p>
        Nous venons de détecter que l'agent HENRI a communiqué sur des
        fréquences chiffrées dans une zone proche de votre emplacement.
      </p>
      <p>
        Nous vous avons donné accès à un analyseur de fréquence. Celui-ci vous
        permet d'écouter ce que notre agent a dit lors de ses communications, à
        condition de savoir sur quelle fréquence il émettait.
      </p>
      <p>
        Les <b>trois derniers documents</b> contiennent sans doute la solution
        pour trouver cette fréquence.
      </p>
      <p>
        <br></br>
        Vous approchez du but !<br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
