import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ParisMessage3LesCroquis() {
  return (
    <BaseMessage title="Les Croquis">
      <p>Bon travail.</p>
      <p>
        L'application s'est ouverte mais elle ne semble contenir que quatre
        croquis dont nous ne comprenons pas la signification.
      </p>
      <p>
        La seule piste qu'il nous reste repose sur les septs fichiers
        verrouillés que nous avons récupérés sur le téléphone de notre agent.
        Mais où trouver leurs codes ?
      </p>
      <p>
        <br />
      </p>
      <p>
        Nous détectons qu'une communication sur une fréquence chiffrée a eu lieu
        dans une zone proche de votre emplacement le jour de la disparition.
      </p>
      <p>
        Nous vous avons donné accès à un{" "}
        <b style={{ paddingLeft: "0.4em" }}>Analyseur de fréquence</b>. Celui-ci
        vous permet d'écouter ce que notre agent a dit lors de ses
        communications, à condition de savoir sur quelle fréquence il émettait.
      </p>
      <p>
        <br />
      </p>
      <p>
        Les quatre croquis sont accessibles dans votre espace{" "}
        <b style={{ paddingLeft: "0.4em" }}>Documents</b>. Nous vous conseillons
        d'en prendre connaissance mais nous pensons que seul le premier d'entre
        eux vous sera utile dans l'immédiat.
      </p>
      <p>
        <br />
      </p>
      <p>
        Notre agent a besoin de vous,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
