import { useState } from "react";
import { CodeFormProps } from "../CodeFormInfos";

export function SixCodesForm(p: CodeFormProps) {
  const [currentCodes, setCurrentCodes] = useState<string[]>(
    new Array(6).fill("")
  );

  function setCodeIdx(idx: number, newVal: string) {
    var nextCodes = [...currentCodes];
    nextCodes[idx] = newVal;
    setCurrentCodes(nextCodes);
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        p.checkCode(
          currentCodes.reduce((acc, curr) => acc + "_" + curr.toString(), "")
        );
      }}
      style={{ marginBottom: "15vh" }}
    >
      {p.errorMessage ? (
        <h2 className="is-family-monospace login-tooltip is-nok">
          {p.errorMessage.split("\n").map((elmt, idx) => (
            <span key={idx}>
              {elmt}
              <br />
            </span>
          ))}
        </h2>
      ) : (
        <></>
      )}
      <div className="field code-app-field">
        <div className="control">
          {currentCodes.map((_, idx) => {
            return (
              <input
                className="input is-family-monospace"
                name="code"
                value={currentCodes[idx]}
                onChange={(e) => setCodeIdx(idx, e.target.value)}
                {...p.formInfos}
                type="number"
                placeholder={`Code Fichier ${idx + 1}`}
                style={{
                  marginBottom: "1em",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              />
            );
          })}
        </div>
        <div className="control">
          <button className="button is-fullwidth is-family-monospace is-active">
            VALIDER
          </button>
        </div>
      </div>
    </form>
  );
}
