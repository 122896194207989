export const OneCodeButton = (p: {
  correct: boolean;
  setCode: (x: number) => void;
  value: string;
  disabled?: boolean;
}) => (
  <div>
    <div className="control">
      <button
        className="button is-fullwidth is-family-monospace is-active is-top is-pale"
        disabled={p.correct || p.disabled}
        onClick={(e) => {
          e.preventDefault();
          p.setCode(1);
        }}
      >
        +
      </button>
    </div>
    <div className="control">
      <p className="input is-fullwidth is-family-monospace no-radius">
        {p.value}
      </p>
    </div>
    <div className="control">
      <button
        className="button is-fullwidth is-family-monospace is-active is-pale"
        disabled={p.correct || p.disabled}
        onClick={(e) => {
          e.preventDefault();
          p.setCode(-1);
        }}
      >
        -
      </button>
    </div>
  </div>
);
