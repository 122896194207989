import { useStoryStatus } from "../../../redux/status/StatusSlice";
import { DocumentPage } from "../../DocumentsElements";
import "../../styles/A4.scss";
import "../../styles/A4unset.scss";
import "./transcriptStyle.scss";

export interface TranscriptionElement {
  duration: number;
  type: string;
  lines?: Array<string>;
}

export function ParisTranscriptionDocument() {
  const storyStatus = useStoryStatus();
  const startDateStr: string | undefined = storyStatus?.startDate;

  if (!startDateStr) {
    return <></>;
  }

  const baseDate = new Date(startDateStr);
  baseDate.setDate(baseDate.getDate() - 8);
  const transcriptionDate = new Date();
  transcriptionDate.setDate(baseDate.getDate() + 2);

  const lines: Array<TranscriptionElement> = [
    { duration: 5, type: "Bruit Blanc" },
    {
      duration: 15,
      type: "Voix Modifiée",
      lines: [
        "Bonjour HENRI. N'enregistrez pas ce message. L'heure est venue de nous rencontrer. Prenez une carte et tracez-y mes indications. " +
          "Je ne me répéterais pas, c'est votre seule chance de trouver le point ALPHA.",
      ],
    },
    { duration: 5, type: "Silence" },
    {
      duration: 28,
      type: "Voix Modifiée",
      lines: [
        "Dans un premier temps, de la gare située entre deux châteaux tracez une ligne droite vers le Sud-Est au-delà des fausses montagnes.",
      ],
    },
    { duration: 3, type: "Silence" },
    {
      duration: 16,
      type: "Voix Modifiée",
      lines: [
        "Une fois fait, recommencez. Placez-vous sur la vraie montagne, traversez le fleuve et regardez plus loin que le chemin.",
      ],
    },
    { duration: 4, type: "Silence" },
    {
      duration: 12,
      type: "Voix Modifiée",
      lines: [
        "Cherchez où se rencontrent ces directions. Le point ALPHA se trouve à 400m au Nord.",
      ],
    },
    { duration: 3, type: "Silence" },
    {
      duration: 50,
      type: "Voix Modifiée",
      lines: [
        "Quand vous serez sur place, ouvrez notre application de communication.",
      ],
    },
    { duration: 5, type: "Silence" },
    {
      duration: 10,
      type: "Voix Modifiée",
      lines: ["Ne laissez aucune trace.", "Bon courage."],
    },
  ];
  var timecodeCurrent = 0;
  return (
    <DocumentPage extraClass="transcript">
      <div className="transcript-header">
        <h1>Message vocal du {baseDate.toLocaleDateString("fr")}</h1>
        <h2>Retranscription du {transcriptionDate.toLocaleDateString("fr")}</h2>
      </div>
      <div className="transcript-main">
        {lines.map((elem) => {
          const returnElem = (
            <div key={timecodeCurrent} className="transcriptElement">
              <p className="strong">
                <span className="timecode">
                  {Math.floor(timecodeCurrent / 60).toLocaleString("fr-FR", {
                    minimumIntegerDigits: 2,
                  })}
                  :
                  {(timecodeCurrent % 60).toLocaleString("fr-FR", {
                    minimumIntegerDigits: 2,
                  })}
                </span>
                <span className="dash">—</span>
                <span className="type">{elem.type}</span>
                <span></span>
              </p>
              {elem.lines ? (
                elem.lines.map((line) => {
                  return <p className="line">{line}</p>;
                })
              ) : (
                <></>
              )}
            </div>
          );

          timecodeCurrent += elem.duration;

          return returnElem;
        })}
        <p className="strong">Fin de l'enregistrement</p>
      </div>
      <div className="transcript-footer">
        <p>La Compagnie · Document Interne F158403365</p>
      </div>
    </DocumentPage>
  );
}
