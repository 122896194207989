import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ParisMessage1Alpha() {
  return (
    <BaseMessage title="Premier Message">
      <p>
        Félicitations,
        <br /> vos déductions semblent exactes.
      </p>
      <p>
        Le téléphone de HENRI a bien borné autour du parc de la Place des fêtes
        le jour de sa disparition.
      </p>
      <p>
        Nous pensons que c'est ici que notre agent à utilisé l'étrange
        application reçue auparavant. Nous venons de vous y donner accès.
      </p>
      <p>
        Vous pouvez retrouvez vos découvertes et nos communications dans la
        section "Messages" de votre espace.
      </p>
      <p>
        <br />
      </p>
      <p>
        Courage,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
