import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="columns">
          <div
            className="column is-half-desktop is-offset-one-quarter-desktop is-centered"
            style={{ textAlign: "center", paddingTop: "3rem" }}
          >
            <h1>Veuillez recharger la page</h1>
            <button
              className="button is-dark"
              style={{ marginTop: "2rem" }}
              onClick={(e) => {
                e.preventDefault();
                // eslint-disable-next-line no-self-assign
                window.location.href = window.location.href;
              }}
            >
              Recharger
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
