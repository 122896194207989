import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ToulouseMessage1Alpha() {
  return (
    <BaseMessage title="Premier Message">
      <p>
        Félicitations,
        <br /> vos déductions semblent exactes.
      </p>
      <p>
        Le téléphone de HENRI a bien été détecté vers la station "Carmes" le
        jour de sa disparition. Il semble s'être arrêté plusieurs minutes à une
        centaine de mètres au Nord de la sortie du métro.
      </p>
      <p>
        Nos services ont intercepté des messages qu'il a reçu à ce moment là
        mais ils sont chiffrés. Peut-être qu'en vous rendant sur place pour
        résoudre cette histoire de fontaine vous trouverez le code utilisé.
      </p>
      <p>
        Vous pouvez retrouvez vos découvertes et nos communications dans la
        section "Messages" de votre espace.
      </p>
      <p>
        <br />
      </p>
      <p>
        Courage,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
