import { useState } from "react";
import { CodeFormProps } from "../../CodeFormInfos";
import BackgroundFrequency from "../../../../imgs/frequencies.png";
import "../../../../styles/audio-player.scss";
import FrequencyControlBar from "./FrequencyControlBar";
import { StoryCodes, StoryConstants } from "../../../../shared/StoryConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/ReduxStore";
import audioFilePath from "../../../../audio/cinqonze_voix.mp3";
import FrequencyPlayer from "../frequencies_common/FrequencyPlayer";

// Cinq Par Onze STOP Seize et Treize STOP 14 deux fois STOP Je répète

export function CodeFrequenciesLyon(p: CodeFormProps) {
  const [currentCode, setCurrentCode] = useState<number>(5000);
  const [playing, setPlaying] = useState(false);

  const storyStatus = useSelector((state: RootState) => state.status.status);
  const [codeSent, setCodeSent] = useState(false);
  const stepIndex = storyStatus?.step?.index;

  function setCodeDiff(diff: number) {
    const value = currentCode + diff;
    var realValue = value < 0 ? 0 : value > 9999 ? 9999 : value;
    setCurrentCode(realValue);
    setPlaying(false);
  }

  if (
    !codeSent &&
    stepIndex &&
    stepIndex > StoryConstants.Steps.Lyon.LA_PLACE
  ) {
    setCurrentCode(3763);
    setCodeSent(true);
  }

  function checkIfSendingCode() {
    if (
      currentCode.toString() === StoryCodes.LYON_PLACE &&
      !codeSent &&
      stepIndex === StoryConstants.Steps.Lyon.LA_PLACE
    ) {
      setCodeSent(true);
      setPlaying(false);
      p.checkCode(currentCode.toString());
    }
  }

  return (
    <>
      <div
        className="bottom-part"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-1rem",
          marginBottom: "3.5rem",
        }}
      >
        <div className="text-encart is-family-monospace">🔍(👨‍🔬🧪,👶➕💀)</div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="field code-app-field inline-buttons">
          <div
            className="core-buttons"
            style={{
              display: "block",
              alignItems: "stretch",
              padding: "0 25px",
              width: "78vw",
              maxWidth: "1000px",
              height: "6vh",
            }}
          >
            <div
              className="is-family-monospace"
              style={{
                position: "relative",
                backgroundImage: `url(${BackgroundFrequency})`,
                backgroundSize: "auto 100%",
                backgroundRepeat: "repeat-x",
                width: "calc(100% + 50px)",
                transform: `translate(-25px,0)`,
                height: "6vh",
                left: 0,
                right: 0,
                top: "0vh",
                borderRadius: "25px",
              }}
            >
              &nbsp;
            </div>

            <div
              style={{
                position: "relative",
                left: `${currentCode / 100}%`,
                transform: `translate(-50%,-100%)`,
                margin: 0,
                padding: 0,
                top: "0vh",
                width: "0.5vw",
                minWidth: "5px",
                maxWidth: "10px",
                backgroundColor: "red",
                height: "6vh",
              }}
            >
              &nbsp;
            </div>

            <p
              className="is-family-monospace"
              style={{
                display: "block",
                position: "relative",
                left: `${currentCode / 100}%`,
                transform: "translate(-50%, -100%)",
                top: "-200%",
                width: "fit-content",
              }}
            >
              {Math.floor(currentCode / 100)
                .toString()
                .padStart(2, "0")}
              .{(currentCode % 100).toString().padStart(2, "0")}
            </p>
          </div>
        </div>
        <div
          className="core-buttons inline-buttons code-app-field"
          style={{ marginTop: "1.5rem" }}
        >
          <FrequencyControlBar
            onAnalyze={() => {
              setPlaying(!playing);
            }}
            setCodeDiff={setCodeDiff}
            analyzeText={playing ? "Arrêter" : "Analyse"}
          />
        </div>
        <FrequencyPlayer
          realAudioUrl={
            currentCode.toString() === StoryCodes.LYON_PLACE
              ? audioFilePath
              : null
          }
          audioDescription={
            currentCode.toString() === StoryCodes.LYON_PLACE
              ? "Cinq par onze. Stop. Seize et treize. Stop. Quatorze, deux fois. Stop. Je répète"
              : undefined
          }
          playing={playing}
          onLoopEnd={checkIfSendingCode}
        />
      </form>
    </>
  );
}
