import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/ReduxStore";
import { RealCluePopup } from "./RealCluePopup";

export function DefaultCluePopup({ hidePopup }: { hidePopup?: () => void }) {
  const storyStatus = useSelector((state: RootState) => state.status);

  if (storyStatus.status === null || storyStatus.status.step === undefined) {
    return (
      <div className="popup">
        <p>Le jeu n'est pas correctement initialisé</p>
      </div>
    );
  } else if (storyStatus.loading || !storyStatus.initialized) {
    return (
      <div className="popup">
        <p>Mise à jour</p>
      </div>
    );
  } else {
    return (
      <RealCluePopup
        storyStatus={storyStatus.status}
        step={storyStatus.status.step}
        hidePopup={hidePopup}
      />
    );
  }

  //  return <></>;
}
