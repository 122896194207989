import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ParisMessage8DonnéesCachées() {
  return (
    <BaseMessage title="Données Cachées">
      <p>Excellent,</p>
      <p>
        Le fichier est déchiffré mais nous suspectons qu'un second message se
        cache à l'intérieur.
      </p>
      <p>
        En prolongeant le texte du fichier, nous pensons que de nouveaux
        caractères vont apparaitre. Une fois ce dernier message révélé, vous
        devriez enfin pouvoir trouver où notre agent a rencontré Dani.
      </p>
      <p>
        <br />
      </p>
      <p>
        C'est la dernière ligne droite,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
