import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ToulouseMessageB9Alternative() {
  return (
    <BaseMessage title="Ultime SMS">
      <p>Attendez !</p>
      <p>
        Nous avons réussi à intercepter un SMS envoyé par HENRI à un numéro
        inconnu. Nous n'avons pas pu identifier qui était derrière celui-ci mais
        nous vous transmettons le message.
      </p>
      <p>
        Cela vous évitera peut-être de suivre les enregistrements et d'ouvrir
        l'application finale sur la clé.
      </p>
      <p>
        <br></br>
        Ne lâchez pas la piste. Nous les tenons !<br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
