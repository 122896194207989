import "../../../styles/home.scss";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { useMessagesArray } from "../../messages/MessagesElements";
import { useDocumentsArray } from "../../../documents/DocumentsElements";
import { useDispatch, useSelector } from "react-redux";
import { unsetAdminStatus } from "../../../redux/admin/AdminState";
import { RootState } from "../../../redux/ReduxStore";
import { TutorialPopup } from "../../../parts/TutorialPopup";
import { TutorialSteps } from "../../../redux/tutorials/TutorialState";
import { StoryConstants } from "../../../shared/StoryConstants";
import { useUpdateShowClue } from "../../../redux/ShowClue";
import { AllAppItems, AppItem } from "./AllAppItems";
import {
  getLyonExtraApps,
  getMainApp,
  getParisExtraApps,
  getToulouseExtraApps,
} from "./AppUtils";

function MenuButton(props: { item: AppItem }) {
  const disabled = props.item.disabled;
  return (
    <Link
      to={`/home/app/${props.item.application}`}
      className={`button cartel cartel-home button-cartel ${
        disabled ? "button-disabled" : ""
      }`}
      onClick={disabled ? (event) => event.preventDefault() : undefined}
    >
      <img src={props.item.img} alt={props.item.alt} className="navbar-item" />
      <p>{props.item.text}</p>
    </Link>
  );
}

export function BodyFull() {
  const statusState = useSelector((state: RootState) => state.status);
  const hasMessages = useMessagesArray();
  const hasDocuments = useDocumentsArray();
  const dispatch = useDispatch();
  useUpdateShowClue(true);

  const items: AppItem[] = [];

  const adminStatus = useSelector((state: RootState) => state.admin.status);
  if (adminStatus) {
    dispatch(unsetAdminStatus());
  }

  var mainApp: AppItem | null | undefined = null;

  if (statusState.status?.story && statusState.status?.step) {
    mainApp = getMainApp(statusState.status?.story, statusState.status?.step);
  }

  if (mainApp == null || mainApp === undefined) {
    mainApp = AllAppItems.Lock;
  }

  items.push(mainApp);

  // Add extra apps
  if (statusState.status?.step?.index) {
    switch (statusState.status?.story?.city) {
      case StoryConstants.City.PARIS:
        items.push(...getParisExtraApps(statusState.status?.step?.index));
        break;
      case StoryConstants.City.LYON:
        items.push(...getLyonExtraApps(statusState.status?.step?.index));
        break;
      case StoryConstants.City.TOULOUSE:
        items.push(...getToulouseExtraApps(statusState.status?.step?.index));
        break;
    }
  }

  if (hasMessages && hasMessages.length > 0) {
    items.push(AllAppItems.Chat);
  } else {
    items.push({ disabled: true, ...AllAppItems.Chat });
  }

  if (hasDocuments && hasDocuments.length > 0) {
    items.push(AllAppItems.Documents);
  }

  return GenerateItemsButton(items);
}

function GenerateItemsButton(items: AppItem[]) {
  // If less than three or odd number, generate full width item(s)
  var fullwidthItemsHtml = (
    items.length % 2 === 1 ? [items[0]] : items.length < 3 ? items : []
  ).map((item) => {
    return (
      <div className="columns" key={item.alt}>
        <div className="column is-fullwidth is-centered">
          <MenuButton item={item} />
        </div>
      </div>
    );
  });

  // Generate line of half width items if more than 2 elements
  var halfItems =
    items.length > 2
      ? items.length % 2 === 1
        ? items.slice(1, items.length)
        : items
      : [];

  var halfItemsHtml: ReactElement[] = [];
  for (var i = 0; i < halfItems.length / 2; i++) {
    halfItemsHtml.push(
      <div className="columns" key={i}>
        <div className="column is-half-desktop is-centered">
          <MenuButton item={halfItems[i * 2]} />
        </div>
        <div className="column is-half-desktop is-centered">
          <MenuButton item={halfItems[i * 2 + 1]} />
        </div>
      </div>
    );
  }

  return (
    <>
      {fullwidthItemsHtml}
      {halfItemsHtml}
      <TutorialPopup
        step={TutorialSteps.Application}
        style={{
          top: "50%",
          minWidth: "300px",
          width: "65vw",
          maxWidth: "95vw",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <p>
          <b>Bienvenue dans la Compagnie</b>
        </p>
        <p>Voici quelques informations sur l'utilisation de votre espace.</p>
        <p>
          Cette zone centrale vous permet de naviguer entre différentes
          sections, dont certaines se débloqueront durant l'aventure.
          <br /> Au départ, utilisez <b style={{ fontSize: "110%" }}> Code </b>
          pour communiquer le point ALPHA à la Compagnie.
        </p>
      </TutorialPopup>
    </>
  );
}
