import "../MessagesBase.scss";
import { LyonTextsMessageStatue } from "../../code/code_types/CodeLyonStatue";
import { BaseMessage } from "../BaseMessage";

export function LyonMessage2Gardiennes() {
  return (
    <BaseMessage
      title="Message &nbsp;de &nbsp;la &nbsp;Gardienne"
      isMonospace={true}
    >
      {LyonTextsMessageStatue.map((t) => (
        <p>{t}</p>
      ))}
    </BaseMessage>
  );
}
