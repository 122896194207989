import { StoryConstants } from "../../../shared/StoryConstants";
import { LinkInfosWithElement } from "../../LinkListPage";
import { ParisMessage1Alpha } from "../pages_paris/ParisMessage1_Alpha";
import { ParisMessage2AppOuverte } from "../pages_paris/ParisMessage2_AppOuverte";
import { ParisMessage3LesCroquis } from "../pages_paris/ParisMessage3_LesCroquis";
import { ParisMessage4LeSquare } from "../pages_paris/ParisMessage4_LeSquare";
import { ParisMessage5SixCodes } from "../pages_paris/ParisMessage5_SixCodes";
import { ParisMessage6LaPiste } from "../pages_paris/ParisMessage6_LaPiste";
import { ParisMessage7DernierFichier } from "../pages_paris/ParisMessage7_DernierFichier";
import { ParisMessage8DonnéesCachées } from "../pages_paris/ParisMessage8_DonneesCachees";
import { ParisMessage9LaFin } from "../pages_paris/ParisMessage9_LaFin";

export function getParis1Messages(stepIdx: number): LinkInfosWithElement[] {
  const toReturn: LinkInfosWithElement[] = [];

  if (stepIdx >= StoryConstants.Steps.Paris.PARC_FETE) {
    toReturn.push({
      infos: {
        url: "premierMessage",
        text: "Premier Message",
      },
      element: <ParisMessage1Alpha />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Paris.GO_BIBLI) {
    toReturn.push({
      infos: {
        url: "appOuverte",
        text: "Application Ouverte",
      },
      element: <ParisMessage2AppOuverte />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Paris.RECHERCHE_FREQUENCE) {
    toReturn.push({
      infos: {
        url: "lesCroquis",
        text: "Les Croquis",
      },
      element: <ParisMessage3LesCroquis />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Paris.GO_SQUARE) {
    toReturn.push({
      infos: {
        url: "leSquare",
        text: "Le Square",
      },
      element: <ParisMessage4LeSquare />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Paris.ON_SQUARE) {
    toReturn.push({
      infos: {
        url: "sixCodes",
        text: "Six Codes",
      },
      element: <ParisMessage5SixCodes />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Paris.WAY_BOLIVAR) {
    toReturn.push({
      infos: {
        url: "laPiste",
        text: "La Piste",
      },
      element: <ParisMessage6LaPiste />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Paris.FONTAINE) {
    toReturn.push({
      infos: {
        url: "dernierFichier",
        text: "Dernier Fichier",
      },
      element: <ParisMessage7DernierFichier />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Paris.STAIRS_MESSAGES) {
    toReturn.push({
      infos: {
        url: "donneesCachees",
        text: "Données Cachées",
      },
      element: <ParisMessage8DonnéesCachées />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Paris.GAME_END) {
    toReturn.push({
      infos: {
        url: "laFin",
        text: "La Fin",
      },
      element: <ParisMessage9LaFin />,
    });
  }

  return toReturn;
}
