import { StoryConstants } from "../../../shared/StoryConstants";
import { StoryMainApi, StoryStepApi } from "../../../shared/StoryElements";
import { AllAppItems, AppItem } from "./AllAppItems";

const AllSteps = StoryConstants.Steps;

function getParisMainApp(): { [id: number]: AppItem | undefined } {
  var squareApp = { ...AllAppItems.PointAlpha, text: "Square" };
  var finalApp = { ...AllAppItems.PointAlpha, text: "Rencontre" };
  var mainApp: { [id: number]: AppItem | undefined } = {};
  mainApp[AllSteps.Paris.FIND_ALPHA] = AllAppItems.PointAlpha;
  mainApp[AllSteps.Paris.PARC_FETE] = AllAppItems.SecretApp;
  mainApp[AllSteps.Paris.GO_BIBLI] = AllAppItems.SecretApp;
  mainApp[AllSteps.Paris.RECHERCHE_FREQUENCE] = AllAppItems.FrequencyCode;
  mainApp[AllSteps.Paris.GO_SQUARE] = squareApp;
  mainApp[AllSteps.Paris.ON_SQUARE] = AllAppItems.Lock;
  mainApp[AllSteps.Paris.WAY_BOLIVAR] = AllAppItems.WalkCode;
  mainApp[AllSteps.Paris.WAY_EQUERRE] = AllAppItems.WalkCode;
  mainApp[AllSteps.Paris.WAY_REBEVAL] = AllAppItems.WalkCode;
  mainApp[AllSteps.Paris.WAY_LAUZIN] = AllAppItems.WalkCode;
  mainApp[AllSteps.Paris.WAY_DUNES] = AllAppItems.WalkCode;
  mainApp[AllSteps.Paris.FONTAINE] = AllAppItems.WalkCode;
  mainApp[AllSteps.Paris.STAIRS_MESSAGES] = {
    ...AllAppItems.LockSms,
    text: "Septième Fichier",
  };
  mainApp[AllSteps.Paris.RECHERCHE_BANC] = finalApp;
  mainApp[AllSteps.Paris.GAME_END] = AllAppItems.FilmCredits;
  mainApp[AllSteps.Paris.SECRET_FOUND] = AllAppItems.FilmCredits;

  return mainApp;
}

function getLyonMainApp(): { [id: number]: AppItem | undefined } {
  var mainApp: { [id: number]: AppItem | undefined } = {};
  mainApp[AllSteps.Lyon.FIND_ALPHA] = AllAppItems.PointAlpha;
  mainApp[AllSteps.Lyon.FIND_STATUE] = undefined;
  mainApp[AllSteps.Lyon.DAME_PERCHEE] = undefined;
  mainApp[AllSteps.Lyon.RENDEZ_VOUS] = AllAppItems.LockSms;
  mainApp[AllSteps.Lyon.THE_SMS] = AllAppItems.LockSms;
  mainApp[AllSteps.Lyon.LA_PLACE] = AllAppItems.FrequencyCode;
  mainApp[AllSteps.Lyon.DEVOIR_MUT] = undefined;
  mainApp[AllSteps.Lyon.STARS] = undefined;
  mainApp[AllSteps.Lyon.FIRST_END] = AllAppItems.FilmCredits;
  mainApp[AllSteps.Lyon.SECOND_LETTER] = AllAppItems.FilmCredits;
  mainApp[AllSteps.Lyon.SECRET_FOUND] = AllAppItems.FilmCredits;

  return mainApp;
}

function getToulouseMainApp(): { [id: number]: AppItem | undefined } {
  var mainApp: { [id: number]: AppItem | undefined } = {};
  mainApp[AllSteps.Toulouse.FIND_ALPHA] = AllAppItems.PointAlpha;
  mainApp[AllSteps.Toulouse.FIND_FOUNTAIN] = undefined;
  mainApp[AllSteps.Toulouse.TRINITE_TO_ASSEZAT] = undefined;
  mainApp[AllSteps.Toulouse.ECHARPE_TABAC] = AllAppItems.SecretApp;
  mainApp[AllSteps.Toulouse.LE_PONT] = AllAppItems.WalkCode;
  mainApp[AllSteps.Toulouse.PISTE_PHOTO] = AllAppItems.WalkCode;
  mainApp[AllSteps.Toulouse.FREQUENCES] = AllAppItems.FrequencyCode;
  mainApp[AllSteps.Toulouse.RECHERCHE_PASSERELLE] = AllAppItems.FrequencyCode;
  mainApp[AllSteps.Toulouse.SQUARE_END] = AllAppItems.FrequencyCode;
  mainApp[AllSteps.Toulouse.GAME_END] = AllAppItems.FilmCredits;
  mainApp[AllSteps.Toulouse.SECRET_FOUND] = AllAppItems.FilmCredits;

  // Alternatives
  mainApp[AllSteps.Toulouse.ALTERNATIVE_PONT] = AllAppItems.LastSms;

  return mainApp;
}

export function getMainApp(
  story: StoryMainApi,
  step: StoryStepApi
): AppItem | undefined {
  const parisMainApp = getParisMainApp();
  const lyonMainApp = getLyonMainApp();
  const toulouseMainApp = getToulouseMainApp();

  switch (story.city) {
    case StoryConstants.City.PARIS:
      return parisMainApp[step.index];
    case StoryConstants.City.LYON:
      return lyonMainApp[step.index];
    case StoryConstants.City.TOULOUSE:
      return toulouseMainApp[step.index];
  }
}

export function getParisExtraApps(stepIndex: number): AppItem[] {
  var extraApps: AppItem[] = [];

  if (stepIndex === StoryConstants.Steps.Paris.GO_SQUARE) {
    extraApps.push(AllAppItems.FrequencyStandalone);
  }

  return extraApps;
}

export function getLyonExtraApps(stepIndex: number): AppItem[] {
  var extraApps = [];

  if (
    stepIndex > StoryConstants.Steps.Lyon.LA_PLACE &&
    stepIndex <= StoryConstants.Steps.Lyon.DEVOIR_MUT
  ) {
    extraApps.push(AllAppItems.FrequencyStandalone);
  }

  return extraApps;
}

export function getToulouseExtraApps(stepIndex: number): AppItem[] {
  var extraApps = [];

  if (
    (stepIndex >= StoryConstants.Steps.Toulouse.LE_PONT &&
      stepIndex <= StoryConstants.Steps.Toulouse.SQUARE_END) ||
    stepIndex === StoryConstants.Steps.Toulouse.ALTERNATIVE_PONT
  ) {
    extraApps.push(AllAppItems.FinalApp);
  }

  return extraApps;
}
