import { useState } from "react";
import { CodeFormProps } from "../../CodeFormInfos";
import BackgroundFrequency from "../../../../imgs/frequencies.png";
import "../../../../styles/audio-player.scss";
import { StoryCodes, StoryConstants } from "../../../../shared/StoryConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/ReduxStore";
import audioPortail from "../../../../audio/toulouse_portail.mp3";
import audioPasserelle from "../../../../audio/toulouse_passerelle.mp3";
import { Link } from "react-router-dom";
import { OneCodeButton } from "../../elements/OneCodeButton";
import FrequencyPlayer from "../frequencies_common/FrequencyPlayer";

// Cinq Par Onze STOP Seize et Treize STOP 14 deux fois STOP Je répète

export function CodeFrequenciesToulouse(p: CodeFormProps) {
  const maxValue = 999999;
  const [currentCodes, setCurrentCodes] = useState<number[]>([
    0, 0, 0, 0, 0, 0,
  ]);
  const [currentCode, setCurrentCode] = useState<number>(0);
  const [playing, setPlaying] = useState(false);

  const storyStatus = useSelector((state: RootState) => state.status.status);
  const [codeSent, setCodeSent] = useState(false);
  const stepIndex = storyStatus?.step?.index;

  function setCodeIdx(idx: number, diff: number) {
    const value = currentCodes[idx] + diff;

    var realValue = value < 0 ? 9 : value > 9 ? 0 : value;
    var nextCodes = [...currentCodes];
    nextCodes[idx] = realValue;
    setCurrentCodes(nextCodes);
    const codeToCheck = nextCodes.reduce((p, curr) => p + curr, "");
    setCurrentCode(Number(codeToCheck));
    setPlaying(false);
  }

  const audioInfos = [
    {
      value: StoryCodes.TOULOUSE_FREQ_1,
      path: audioPortail,
      transcription:
        "[Bruits de rue] Oui c'est moi. Je suis sur la plaque. Je vois le portail. On change avant d'être sous le pont ? C'est noté.",
      url: audioPortail,
    },
    {
      value: StoryCodes.TOULOUSE_FREQ_2,
      path: audioPasserelle,
      transcription:
        "[Bruits d'eau] C'est bon. J'entends rien sur la passerelle. [Bruits de pas]  12 Amont. F. Ok ! Le programme ? Je fais ça de suite.",
      url: audioPasserelle,
    },
  ];

  function checkIfSendingCode() {
    if (!codeSent) {
      if (
        (stepIndex === StoryConstants.Steps.Toulouse.FREQUENCES &&
          currentCode === audioInfos[0].value) ||
        (stepIndex === StoryConstants.Steps.Toulouse.RECHERCHE_PASSERELLE &&
          currentCode === audioInfos[1].value)
      ) {
        setCodeSent(true);
        setPlaying(false);
        p.checkCode(currentCode.toString());
      }
    }
  }

  const currentAudioInfos = audioInfos.find((x) => x.value === currentCode);

  return (
    <>
      <div
        className="bottom-part"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-1rem",
          marginBottom: "2.2rem",
        }}
      >
        {stepIndex === StoryConstants.Steps.Toulouse.FREQUENCES ? (
          <Link
            className="button is-dark"
            to="/home/app/documents"
            style={{ border: "1px solid #bbb" }}
          >
            Voir les documents
          </Link>
        ) : stepIndex === StoryConstants.Steps.Toulouse.RECHERCHE_PASSERELLE ? (
          <div className="text-encart is-family-monospace">
            Fréquence 1: 60138 kHz
          </div>
        ) : (
          <div className="text-encart is-family-monospace">
            Fréquence 1:&nbsp;&nbsp;60138 kHz
            <br />
            Fréquence 2:&nbsp;200598 kHz
          </div>
        )}
      </div>
      <div style={{ margin: "0 auto 1.5rem", width: "fit-content" }}>
        <div
          className="core-buttons inline-buttons code-app-field"
          style={{ marginBottom: "1.2rem", width: "fit-content" }}
        >
          <div className="core-buttons">
            {new Array(6).fill(null).map((_, i) => {
              return (
                <OneCodeButton
                  correct={p.correct}
                  setCode={(x) => setCodeIdx(i, x)}
                  value={currentCodes[i].toString()}
                  key={i}
                />
              );
            })}
          </div>
        </div>
        <button
          className="button size-fix is-pale is-family-monospace"
          onClick={(e) => {
            e.preventDefault();
            setPlaying(!playing);
          }}
          style={{ width: "100%" }}
        >
          {playing ? "Arrêter" : "Lancer"} Analyse
        </button>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FrequencyPlayer
          realAudioUrl={currentAudioInfos?.url}
          audioDescription={currentAudioInfos?.transcription}
          playing={playing}
          onLoopEnd={checkIfSendingCode}
        />
      </form>
    </>
  );
}
