import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ToulouseMessage8NouvelleFrequence() {
  return (
    <BaseMessage title="Nouvelle Fréquence">
      <p>
        C'était donc 60138kHz. Vous êtes sur la bonne voie mais ils semblent
        qu'ils aient changé de fréquence quasi immédiatemment.
      </p>
      <p>
        <br />
        Nous vous laissons suivre la piste. Ne nous décevez pas.
      </p>
      <p>
        <span className="italic bold">La Compagnie</span>
        <br />
        <br />
      </p>
      <p style={{ fontSize: "90%" }}>
        <strong>Information importante : </strong>
        <em>
          La suite de l'aventure contient une ou plusieurs sections parfois
          fermées au public. Un itinéraire alternatif est disponible dans le
          bouton "Aide" en haut à droite.
        </em>
      </p>
    </BaseMessage>
  );
}
