import { CSSProperties } from "react";
import LandingConstants from "./LandingConstants";
import Insta from "../../imgs/instagram.svg";
import Config from "../../Config";
import { Link } from "react-router-dom";
import { Expand } from "../../parts/Expand";

interface Props {
  useDelay: boolean;
}

function NewsletterForm({ useDelay }: Props) {
  const delay =
    LandingConstants.baseDelay +
    LandingConstants.tagLineDuration +
    LandingConstants.taglineDelay +
    LandingConstants.imageDuration +
    LandingConstants.imageDelay;

  let style: CSSProperties = {
    WebkitAnimationDelay: delay + "s",
    animationDelay: delay + "s",
    animationDuration: LandingConstants.newsletterDuration + "s",
    WebkitAnimationDuration: LandingConstants.newsletterDuration + "s",
  };

  const stripeInfos = Config.stripe;

  return (
    <section
      className="section is-mobile is-centered bottomPart appear"
      style={useDelay ? style : {}}
    >
      <div
        className="columns is-mobile is-centered is-fullwidth"
        style={{ padding: "0 0.75rem", marginTop: ".1rem" }}
      >
        {stripeInfos ? (
          <Link
            className="is-fullwidth button is-danger is-medium big"
            type="submit"
            to={
              stripeInfos.url +
              (stripeInfos.promoCode
                ? `?prefilled_promo_code=${stripeInfos.promoCode}`
                : "")
            }
            style={{ overflow: "hidden", backgroundColor: "#ef2e55" }}
          >
            {stripeInfos.promoCode ? "Précommandez" : "Commandez"}
            {" un pack "}
            <span style={{ fontWeight: 700, paddingLeft: "5pt" }}>
              Paris
            </span>,{" "}
            <span style={{ fontWeight: 700, padding: "0 5pt" }}>Lyon</span> ou
            <span style={{ fontWeight: 700, padding: "0 5pt" }}>Toulouse</span>
            {stripeInfos.promoCode ? (
              <span className="above-500px">pour Mai 2025</span>
            ) : (
              <></>
            )}
          </Link>
        ) : (
          <></>
        )}
      </div>

      {stripeInfos ? (
        <div className="is-fullwidth is-centered text-center columns">
          <p
            style={{
              fontStyle: "",
              fontSize: "80%",
              marginBottom: "-0.3rem",
            }}
          >
            {stripeInfos.promoCode ? (
              <>
                <span className="under-500px">
                  Envoi Mai 2025
                  <br />{" "}
                </span>
                <span style={{ fontWeight: "" }}>Prix Précommande 9,50€</span> (
                <span style={{ textDecoration: "line-through" }}>14,50€</span>,{" "}
                <Link className="lightLink" to="/cgv">
                  voir CGV
                </Link>
                )
              </>
            ) : (
              <>
                <span style={{ fontWeight: "" }}>Prix : 14,50€</span> (
                <Link className="lightLink" to="/cgv">
                  voir CGV
                </Link>
                )
              </>
            )}
          </p>
        </div>
      ) : (
        <></>
      )}
      <Expand />
      <div className="columns is-mobile is-centered">
        <div>
          <a
            href="https://www.instagram.com/lacompagnie.jeu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{
                maxWidth: "10vw",
                maxHeight: "8vh",
                margin: "auto",
                display: "block",
                filter: "drop-shadow(0 0px 2px #ddd)",
              }}
              src={Insta}
              alt="Logo Instagram"
            />
            <p
              style={{
                color: "#eee",
                marginTop: "-0.2vh",
                marginBottom: "1vh",
              }}
            >
              Suivez-nous
            </p>
          </a>
        </div>
      </div>

      {Config.showCodeButton ? (
        <div className="columns is-mobile is-centered">
          <Link
            to="/code"
            className="button is-danger"
            style={{ backgroundColor: "#e25571" }}
          >
            Vous avez résolu une enquête sur nos réseaux ?
          </Link>
        </div>
      ) : (
        <></>
      )}
      <Expand />
    </section>
  );
}

export default NewsletterForm;
