import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ParisMessage9LaFin() {
  return (
    <BaseMessage title="Remerciements">
      <p>Félicitations, vous avez réussi.</p>
      <p>
        Ainsi ils s'étaient donnés rendez-vous sur la butte Montmartre, vers la
        basilique.
      </p>
      <p>
        Nos équipes vont se charger de suivre les signaux qu'ils ont pu y
        laisser.
      </p>
      <p>
        Nous ferons de notre mieux, mais si HENRI reste introuvable, nous
        devrons nous concentrer sur cette prétendue source.
      </p>
      <p>
        <br />
      </p>
      <p>
        Merci de nous avoir aidé dans cette enquête. Nous vous recontacterons si
        nécessaire.
      </p>
      <p>
        <br />
      </p>
      <p>
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
