import { DocumentPage } from "../../DocumentsElements";
import "../../styles/A4.scss";
import "../../styles/A4unset.scss";
import map from "./CarteParis.png";

export function ParisMapDocument() {
  return (
    <DocumentPage extraClass="no-padding">
      <img src={map} alt="Carte de Paris" />
    </DocumentPage>
  );
}
