import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ParisMessage4LeSquare() {
  return (
    <BaseMessage title="Le Square">
      <p>Vous semblez avoir retrouvé la piste de notre agent, félicitations.</p>
      <p>
        Vous devriez utilisez ce message audio pour identifier le "Square" dont
        il parle.
      </p>
      <p>Communiquez-nous le nom de ce square dès que vous vous y trouverez.</p>
      <p>
        <br />
      </p>
      <p>
        Ne nous décevez pas,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
