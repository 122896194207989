import { StoryConstants } from "../../shared/StoryConstants";
import { CodeFormGPS } from "./code_types/CodeFormGPS";
import { CodeFormLinkAndImage } from "./code_types/CodeFormLinkAndImage";
import { CodeFormLinks } from "./code_types/CodeFormLinks";
import { CodeLyonStatue } from "./code_types/CodeLyonStatue";
import { CodeRendezVous } from "./code_types/CodeRendezVous";
import { CodeFrequenciesLyon } from "./code_types/frequencies_lyon/CodeFrequenciesLyon";
import { CodeFormInfos } from "./CodeFormInfos";
import EndImagePath from "../../imgs/loin_cropped.png";

export function CodeListLyon1(
  stepIdx: number,
  baseInfos: CodeFormInfos
): CodeFormInfos | undefined {
  switch (stepIdx) {
    case StoryConstants.Steps.Lyon.FIND_ALPHA:
      return {
        ...baseInfos,
        minLength: 3,
        placeholder: "Point ALPHA",
      };
    case StoryConstants.Steps.Lyon.FIND_STATUE:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeLyonStatue,
        subheader: "Rendez-vous au point ALPHA et cherchez le code",
      };
    case StoryConstants.Steps.Lyon.DAME_PERCHEE:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeFormGPS,
        header: "La Dame Perchée",
        subheader:
          "Allez voir cette dame et donnez-nous leur lieu de rendez-vous.",
      };
    case StoryConstants.Steps.Lyon.RENDEZ_VOUS:
    case StoryConstants.Steps.Lyon.THE_SMS:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeRendezVous,
        header: "La Conversation",
        subheader: "La source semble avoir rencontré HENRI au 5 rue Coustou",
        isCentered: false,
      };
    case StoryConstants.Steps.Lyon.LA_PLACE:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeFrequenciesLyon,
        header: "Fréquences",
        subheader: "Sélectionner une fréquence à déchiffrer",
        isCentered: false,
      };
    case StoryConstants.Steps.Lyon.DEVOIR_MUT:
      return {
        ...baseInfos,
        minLength: 4,
        placeholder: "Mot de passe du dossier",
        header: "La Clé USB",
        subheader: "Nous avons besoin de vous pour déchiffrer cette clé",
        formElement: CodeFormLinks,
        links: [
          {
            to: "/home/messages/cleUsb",
            str: "Message de la Compagnie",
          },
          {
            to: "/document/stolen_message",
            str: "Courrier de DANI à HENRI",
          },
          {
            to: "/home/app/frequencies",
            str: "Application Fréquences",
          },
        ],
      };
    case StoryConstants.Steps.Lyon.STARS:
      return {
        ...baseInfos,
        placeholder: "Lieu du rendez-vous",
        header: "Le Rendez-Vous",
        subheader: "Où l'informatrice a-t-elle rencontré Henri ?",
        formElement: CodeFormLinkAndImage,
        links: [
          {
            to: "/document/stolen_message",
            str: "Courrier de DANI à HENRI",
          },
        ],
        image: {
          src: EndImagePath,
          alt: "Un schéma trouvé sur la clé USB",
          subtitle: "Le schéma trouvé sur la clé",
        },
      };
  }

  return undefined;
}
