import { useState } from "react";
import { CodeFormProps } from "../CodeFormInfos";

import BirdImg from "../../../imgs/toulouse_app/bird.svg";
import DumbbellImg from "../../../imgs/toulouse_app/dumbbell.svg";
import RockImg from "../../../imgs/toulouse_app/rock.svg";
import LeafImg from "../../../imgs/toulouse_app/leaf.svg";
import OliveOilImg from "../../../imgs/toulouse_app/olive-oil.svg";
import WaterImg from "../../../imgs/toulouse_app/water.svg";
import styled from "styled-components";

const DotSpan = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  margin-bottom: 0.1rem;
`;

const ColorEmpty = "#555";
const ColorDone = "#e8e337";

export function CodeToulouseSymboles(p: CodeFormProps) {
  const [currentCode, setCurrentCode] = useState<string>("E");

  function addToCode(a: string) {
    setCurrentCode(currentCode + a);
  }

  function ButtonWithImg({
    img,
    alt,
    letter,
  }: {
    img: string;
    alt: string;
    letter: string;
  }) {
    return (
      <div className="column is-half">
        <button
          className={`button cartel cartel-home button-cartel ${
            currentCode.includes(letter) ? "button-disabled" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            if (!currentCode.includes(letter)) {
              addToCode(letter);
            }
          }}
          style={{ maxHeight: "18vh" }}
        >
          <img src={img} alt={alt} />
        </button>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (currentCode !== "EBDCAF") {
          setCurrentCode("E");
        } else {
          p.checkCode(currentCode);
        }
      }}
      style={{ marginBottom: "1vh" }}
    >
      <div className="columns is-centered">
        {Array(6)
          .fill(0)
          .map((_, i) => (
            <DotSpan color={i < currentCode.length ? ColorDone : ColorEmpty} />
          ))}
      </div>
      <div className="columns">
        <ButtonWithImg img={BirdImg} alt="Oiseau" letter="A" />
        <ButtonWithImg img={DumbbellImg} alt="Haltères" letter="B" />
      </div>
      <div className="columns">
        <ButtonWithImg img={RockImg} alt="Pierre" letter="C" />
        <ButtonWithImg img={LeafImg} alt="Feuille" letter="D" />
      </div>
      <div className="columns">
        <ButtonWithImg img={OliveOilImg} alt="Huile d'olive" letter="E" />
        <ButtonWithImg img={WaterImg} alt="Goutte d'eau" letter="F" />
      </div>
      <div className="control">
        <button
          className="button is-fullwidth is-family-monospace is-active"
          disabled={currentCode.length < 6}
        >
          VALIDER
        </button>
      </div>
    </form>
  );
}
