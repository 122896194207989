import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";
import { ToulouseTextsMessageHotel } from "../../code/code_types/CodeToulouseHotel";

export function ToulouseMessage4Hotel() {
  return (
    <BaseMessage title="Message &nbsp;de &nbsp;l'hôtel" isMonospace={true}>
      {ToulouseTextsMessageHotel.map((t) => (
        <p>{t}</p>
      ))}
    </BaseMessage>
  );
}
