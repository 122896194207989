export function ToulouseFinalSizeSelector({
  disabled,
  squareSize,
  editSquareSize,
}: {
  squareSize: number;
  disabled: boolean;
  editSquareSize: (x: number) => void;
}) {
  return (
    <div className="final-size-selector">
      <button
        className="button is-family-monospace is-active is-left is-pale"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          editSquareSize(-1);
        }}
      >
        -
      </button>
      <div className="input is-family-monospace">
        <p>{squareSize}</p>
      </div>
      <button
        className="button is-family-monospace is-active is-pale is-right"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          editSquareSize(+1);
        }}
      >
        +
      </button>
    </div>
  );
}

export function ToulouseFinalTopButton({
  showOpenButton,
  showCodeInput,
  setShowCodeInput,
  percent,
  onLoadingClick,
  isLoading,
}: {
  showOpenButton: boolean;
  showCodeInput: boolean;
  setShowCodeInput: (x: boolean) => void;
  isLoading: boolean;
  percent: number;
  onLoadingClick: () => void;
}) {
  return !showOpenButton ? (
    <button
      className="button is-family-monospace is-active is-pale final-selector"
      disabled={isLoading}
      onClick={(e) => {
        e.preventDefault();
        onLoadingClick();
      }}
    >
      {isLoading
        ? "Chargement" +
          (Math.round(2 * percent) % 3 === 0
            ? ""
            : Math.round(2 * percent) % 3 === 1
            ? "."
            : "..")
        : "Sélectionner"}
    </button>
  ) : (
    <button
      className="button is-family-monospace is-pale final-selector"
      style={{ backgroundColor: showCodeInput ? "#fcc" : undefined }}
      onClick={(e) => {
        e.preventDefault();
        setShowCodeInput(!showCodeInput);
      }}
    >
      {showCodeInput ? "Retour" : "Accéder"}
    </button>
  );
}
