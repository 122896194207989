import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ParisMessage6LaPiste() {
  return (
    <BaseMessage title="Six Codes">
      <p>Vous nous étonnez.</p>
      <p>
        Les clés nous ont permis d'extraire six images. Elles sont désormais
        disponibles également dans l'espace{" "}
        <b style={{ paddingLeft: "0.4em" }}>Documents</b>.
      </p>
      <p>
        Puisque vous semblez si fort, nous vous laissons suivre cette piste par
        vous-même. Il faut sans doute partir du bas du square Bolivar.
      </p>
      <p>
        Bien sûr, nous continuons à vous surveiller.
        <b style={{ paddingLeft: "0.4em" }}>Communiquez-nous chaque rue</b> que
        vous empruntez.
      </p>
      <p>
        <br />
      </p>
      <p>
        En espérant que vous finirez par trouver le code du septième fichier...
      </p>
      <p>
        Vous approchez du but,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
