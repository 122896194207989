import "../../styles/A4.scss";
import "../../styles/A4unset.scss";
import "./stolen.scss";
import "../2_transcription/transcriptStyle.scss";
import { useStoryStatus } from "../../../redux/status/StatusSlice";
import { DocumentPage } from "../../DocumentsElements";
import { StoryConstants } from "../../../shared/StoryConstants";

export interface TranscriptionElement {
  duration: number;
  type: string;
  lines?: Array<string>;
}

export function LyonStolenDocument() {
  const storyStatus = useStoryStatus();

  const censoredText = [
    "Une fois fait,",
    "nous aurons besoin d'un endroit sûr pour échanger.",
    "Montez un dernier escalier jusqu'à la place avec la petite fontaine au lion. Depuis l'étoile de cette place, utilisez l'image sur la clé USB pour trouver où je vous attendrais.",
  ].join(" ");

  function censorText(inputText: string) {
    return (
      <span className="censored">{inputText.replace(/[^\s\\]/g, "\xa0")}</span>
    );
  }

  var isCensored =
    storyStatus?.step?.index &&
    storyStatus?.step?.index < StoryConstants.Steps.Lyon.STARS;

  return (
    <DocumentPage extraClass="page-a5 stolen-note">
      <div className="main">
        <p>
          C'est moi, je vous fais passer ce message avant notre rendez-vous.
        </p>
        <p>
          Vous avez du remarquer qu'il faut un code pour accéder à la clé USB.
          Ce mot de passe, c'est ce qui nous lie.
        </p>
        <p>
          Pour le trouver vous devrez vous trouver face à un chimiste. Une de
          ses compagnes vous pointera une marque. Suivez le chemin courbé tout
          contre cette marque sur une vingtaine de mètres jusqu'à vous trouver à
          côté. C'est à ce moment qu'il faudra suivre ma voix.
        </p>
        <p>
          Quand vous sortirez du dernier sous-sol, n'empruntez pas immédiatement
          les escaliers vers la lumière ! Restez sur place et cherchez-y les
          pionniers. Leur première création, c'est ce qui nous lie.
        </p>
        <p>{isCensored ? censorText(censoredText) : censoredText}</p>
        <p>Demain tout sera clair.</p>
        <div className="signature">DANI</div>
      </div>
      <div className="warning-stamp">
        <h1>{isCensored ? "Confidentiel" : "Haute Confidentialité"}</h1>
        <h2>Document à accréditation</h2>
      </div>
    </DocumentPage>
  );
}
