import { useState } from "react";
import { CodeFormProps } from "../../CodeFormInfos";

import BalanceImg from "./imgs/playground.min.svg";
import BikeImg from "./imgs/bike.svg";
import BallImg from "./imgs/football.svg";
import BinImg from "./imgs/bin.svg";
import BirdImg from "./imgs/bird.svg";
import LeashImg from "./imgs/leash.svg";
import LightningImg from "./imgs/lightning.svg";
import ScooterImg from "./imgs/scooterClean.svg";

import styled from "styled-components";

const DotSpan = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  margin-bottom: 0.1rem;
`;

const ColorEmpty = "#555";
const ColorDone = "#e8e337";

export function CodeParisSymboles(p: CodeFormProps) {
  const defaultCode = "00000000";
  const [currentCode, setCurrentCode] = useState<string>(defaultCode);

  function setLetter(idx: number, newChar: string) {
    var newCode =
      currentCode.substring(0, idx) +
      newChar +
      currentCode.substring(idx + newChar.length);
    setCurrentCode(newCode);
  }

  function ButtonWithImg({
    img,
    alt,
    idx,
  }: {
    img: string;
    alt: string;
    idx: number;
  }) {
    const enabledStyle: React.CSSProperties = {
      filter:
        "grayscale(100%) brightness(0) invert(90) brightness(0.9) drop-shadow(3px 3px 4px green)",
    };
    const letter = currentCode.charAt(idx);
    const isDisabled = letter === "0";
    return (
      <div className="column is-half">
        <button
          className={`button cartel cartel-home button-cartel ${
            isDisabled ? "button-disabled" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            setLetter(idx, isDisabled ? "1" : "0");
          }}
          style={{
            maxHeight: "14vh",
            backgroundColor: isDisabled ? "#1a0f0f" : "#0f1a0f",
          }}
        >
          <img src={img} alt={alt} style={isDisabled ? {} : enabledStyle} />
        </button>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (currentCode !== "01101100") {
          setCurrentCode(defaultCode);
        } else {
          p.checkCode(currentCode);
        }
      }}
      style={{ marginBottom: "0.2vh", marginTop: "-0.5rem" }}
    >
      <div className="columns">
        <ButtonWithImg img={BallImg} alt="Ballon" idx={0} />
        <ButtonWithImg img={BinImg} alt="Poubelle" idx={1} />
      </div>
      <div className="columns">
        <ButtonWithImg img={ScooterImg} alt="Scooter" idx={2} />
        <ButtonWithImg img={BikeImg} alt="Vélo" idx={3} />
      </div>
      <div className="columns">
        <ButtonWithImg img={BirdImg} alt="Oiseau" idx={4} />
        <ButtonWithImg img={BalanceImg} alt="Balancelle" idx={5} />
      </div>
      <div className="columns">
        <ButtonWithImg img={LightningImg} alt="Eclair" idx={6} />
        <ButtonWithImg img={LeashImg} alt="Laisse" idx={7} />
      </div>
      <div className="control">
        <button
          className="button is-fullwidth is-family-monospace is-active"
          disabled={currentCode === defaultCode}
        >
          VALIDER
        </button>
      </div>
    </form>
  );
}
