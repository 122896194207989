import { Expand } from "../../parts/Expand";
import { BackButtonFlex } from "../../parts/BackButton";
import "../../styles/fireworks.scss";
import TickOkWhite from "../../imgs/tick_circle_white.svg";

function CorrectCodeApp() {
  return (
    <>
      <div
        className="text-center leftFadeIn"
        style={{
          flexGrow: 2,
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ alignSelf: "center", opacity: 0.9 }}>
          <img
            src={TickOkWhite}
            alt="Tick de validation"
            style={{ height: "30vh", maxHeight: "250px", marginBottom: "1rem" }}
          />
          <h1 style={{ color: "#fff", textShadow: "unset" }}>Code Juste</h1>
        </div>
      </div>
      <Expand />
      <BackButtonFlex />
    </>
  );
}

export default CorrectCodeApp;
