import "../../styles/A4.scss";
import "../../styles/A4unset.scss";
import "./simpleDoc.scss";
import "../2_transcription/transcriptStyle.scss";
import { useNavigate } from "react-router";
import { PropsWithChildren } from "react";

export function ToulouseUsbDocumentPont() {
  return (
    <ToulouseUsbDocument>
      <p className="title">fichier1.txt</p>
      <p>
        À une cinquantaine de mètres se trouve un pont avec 6 piles. Quand vous
        serez environ à sa moitié, regardez le panneau côté amont. RDV sous la
        carte<span className="blink"></span>
      </p>
    </ToulouseUsbDocument>
  );
}

export function ToulouseUsbDocumentLight() {
  return (
    <ToulouseUsbDocument>
      <p className="title">fichier7.txt</p>
      <p>
        Dernier problème, 100m à droite. Restez sur le chemin de croix et
        tournez vous vers la lumière. Suivez mon appel.
        <span className="blink"></span>
      </p>
    </ToulouseUsbDocument>
  );
}

function ToulouseUsbDocument({ children }: PropsWithChildren) {
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="text-document">{children}</div>
      <div className="floating-bottom is-fullwidth">
        <button
          className="button is-medium"
          onClick={() => {
            if (window.history.length > 1) {
              navigate(-1); // Go back to the previous page
            } else {
              navigate("/"); // No history, go to home
            }
          }}
        >
          Retour
        </button>
      </div>
    </div>
  );
}
