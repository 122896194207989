import { CSSProperties, useState } from "react";
import ClockImgOk from "../imgs/clockOk.svg";
import ClockImgWarn from "../imgs/clockOrange.svg";
import ClockImgRed from "../imgs/clockRed.svg";
import { BasicModal } from "./BasicModal";
import { RootState } from "../redux/ReduxStore";
import { StorySchedule } from "../shared/StoryElements";
import { useSelector } from "react-redux";

export function ScheduleButton() {
  const [popupVisible, setPopupVisible] = useState(false);
  const schedule = useSelector(
    (state: RootState) => state.status.status?.story?.schedule
  );

  if (!schedule) {
    return <></>;
  }

  // Check color

  const scheduleDoW = getScheduleDoW(schedule);
  if (!scheduleDoW) {
    return <></>;
  }

  const dayHours = getDayHours(scheduleDoW);
  if (!dayHours) {
    return <></>;
  }
  // Get difference
  const date = new Date();
  var currentH = date.getHours() + date.getMinutes() / 60;

  var statusImg: string = ClockImgOk;
  var statusStyles: CSSProperties = {};

  if (currentH < dayHours[0] || currentH > dayHours[1]) {
    statusImg = ClockImgRed;
    statusStyles = {
      background: "#622",
      borderWidth: "3px",
      borderColor: "#f30",
    };
  } else if (dayHours[1] - currentH <= 1.5) {
    statusImg = ClockImgWarn;
    statusStyles = {
      background: "#630",
      borderWidth: "3px",
      borderColor: "#f50",
    };
  }

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          setPopupVisible(!popupVisible);
        }}
        className="button navbar-button is-rounded"
        style={{
          ...statusStyles,
        }}
      >
        <img src={statusImg} alt="Horloge" />
        {statusImg === ClockImgRed ? (
          <p
            style={{ marginLeft: "6px", fontWeight: "700" }}
            className="above-700px"
          >
            Fermé
          </p>
        ) : (
          <></>
        )}
      </button>
      {/* Modal horaires */}
      <BasicModal popupVisible={popupVisible} setPopupVisible={setPopupVisible}>
        <SchedulePopup scheduleDoW={scheduleDoW} scheduleImg={statusImg} />
      </BasicModal>
    </>
  );
}

function getScheduleDoW(
  schedule: StorySchedule
): undefined | { [fromToDOW: string]: string } {
  const currentDate = new Date();
  const dayCode = (currentDate.getMonth() + 1) * 100 + currentDate.getDate();

  function buildKeyDayCode(k: string) {
    const [keyDay, keyMonth] = k.split("/");
    return 100 * Number(keyMonth) + Number(keyDay);
  }

  for (let key in schedule) {
    const [keyStart, keyEnd] = key.split("-");
    var keyStartCode = buildKeyDayCode(keyStart);
    var keyEndCode = buildKeyDayCode(keyEnd);
    if (dayCode >= keyStartCode && dayCode <= keyEndCode) {
      return schedule[key];
    }
  }
  return undefined;
}

function getDayHours(scheduleDoW: { [fromToDOW: string]: string }) {
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay() > 0 ? currentDate.getDay() : 7; // 1-7: Mon-Sun

  for (let key in scheduleDoW) {
    const [keyStart, keyEnd] = key.split("-").map((v) => Number(v));
    if (dayOfWeek >= keyStart && dayOfWeek <= keyEnd) {
      return scheduleDoW[key].split("-").map((v) => Number(v));
    }
  }

  return undefined;
}

function SchedulePopup({
  scheduleDoW,
  scheduleImg,
}: {
  scheduleDoW: { [fromToDOW: string]: string };
  scheduleImg: string;
}) {
  const listDays = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  var sentences: { [key: string]: string } = {};

  for (let key in scheduleDoW) {
    const [dayStart, dayEnd] = key.split("-").map((v) => Number(v) - 1);

    var daySentence =
      dayStart !== dayEnd
        ? `${listDays[dayStart]} au ${listDays[dayEnd]}`
        : listDays[dayStart];

    const [hourStart, hourEnd] = scheduleDoW[key]
      .split("-")
      .map((v) => Number(v))
      .map((x) => {
        return `${formatTimePart(Math.floor(x))}h${formatTimePart(
          Math.floor((x % 1).toFixed(2) * 60)
        )}`;
      });

    sentences[daySentence] = `${hourStart} à ${hourEnd}`;
  }

  return (
    <>
      <h2
        style={{ fontWeight: "600", fontSize: "110%", marginBottom: "0.25rem" }}
      >
        Horaires
      </h2>
      {scheduleImg === ClockImgWarn ? (
        <p style={{ marginBottom: "0.2rem" }}>
          <b>Vous approchez des limites horaires de la route principale</b>
        </p>
      ) : (
        <></>
      )}
      {scheduleImg === ClockImgRed ? (
        <p style={{ marginBottom: "0.2rem" }}>
          <b>Vous ne pourrez plus compléter la route principale aujourd'hui</b>
        </p>
      ) : (
        <></>
      )}
      <p>
        Cette histoire contient <b>une route principale</b> passant par des
        espaces pouvant être fermés au public.{" "}
        <b>Des itinéraires alternatifs</b> sont proposés dans l'onglet "Aide"
        lors de ces sections.
        <br />
        <br />- Le chemin principal est faisable sur les horaires suivants :
      </p>
      <ul style={{ marginLeft: "1rem" }}>
        {Object.keys(sentences).map((dayS, i) => (
          <li key={i}>
            <b>• {dayS} :</b> {sentences[dayS]}
          </li>
        ))}
      </ul>
    </>
  );
}

function formatTimePart(x: number) {
  return x.toString().padStart(2, "0");
}
