import { useSelector } from "react-redux";
import { RootState } from "../../redux/ReduxStore";
import { BackButtonFlex } from "../../parts/BackButton";
import "./CodeApp.scss";
import { useNavigate } from "react-router";
import { CodeFormsInfos } from "./CodeUtils";
import { Expand } from "../../parts/Expand";
import { StoryConstants } from "../../shared/StoryConstants";
import { CodeFormInfos } from "./CodeFormInfos";
import { useEffect, useMemo } from "react";
import { LoadingPage } from "../LoadingPage";

export function FrequencyApp() {
  const navigate = useNavigate();
  const statusState = useSelector((state: RootState) => state.status.status);

  // Check access
  var formInfos = useMemo<CodeFormInfos | undefined>(() => {
    var nextFormInfos = undefined;
    if (
      statusState?.story?.index === StoryConstants.Story.FIRST_MISSION &&
      statusState?.story?.city === StoryConstants.City.LYON &&
      statusState?.step &&
      statusState?.step?.index > StoryConstants.Steps.Lyon.LA_PLACE
    ) {
      nextFormInfos = CodeFormsInfos.LA_PLACE;
    }
    if (
      statusState?.story?.index === StoryConstants.Story.FIRST_MISSION &&
      statusState?.story?.city === StoryConstants.City.PARIS &&
      statusState?.step &&
      statusState?.step?.index === StoryConstants.Steps.Paris.GO_SQUARE
    ) {
      //
      nextFormInfos = CodeFormsInfos.PARIS_FREQ;
    }

    return nextFormInfos;
  }, [statusState]);

  if (statusState != null && formInfos === undefined) {
    navigate("/home");
  }

  const correct = false;
  const errorMessage = null;

  async function checkCode(code: string) {
    // NO CHECK IN THIS APP
  }

  return !formInfos ? (
    <p>Chargement</p>
  ) : (
    <>
      <div
        className={`block-content ${
          formInfos.isCentered ? "centered-body" : ""
        } text-center code-app-body`}
      >
        <div className="columns normal" style={{ marginBottom: "1.5rem" }}>
          <div className="column">
            <h3>{formInfos.header}</h3>
            {formInfos?.subheader ? (
              <p className="thin">{formInfos.subheader}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="columns is-fullwidth code-app-code-columns">
          <div className="column is-fullwidth is-centered">
            <formInfos.formElement
              {...{
                correct,
                formInfos,
                checkCode,
                errorMessage,
              }}
            />
          </div>
        </div>
      </div>
      <Expand />
      <BackButtonFlex />
    </>
  );
}
