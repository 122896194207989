import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ToulouseMessage5CleUsb() {
  return (
    <BaseMessage title="La Clé Usb">
      <p>Vous touchez au but.</p>
      <p>
        Nous ne pensions pas avoir besoin de vous l'annoncer mais nous avions
        trouvé d'autres documents chez HENRI. Principalement, un courrier et une
        clé USB chiffrée qui semblent provenir du même informateur.
      </p>
      <p>Nous ne pensons pas avoir besoin de vous communiquer le courrier.</p>
      <p>
        Pour la clé, nous n'avons pu extraire que 6 caractères et un étrange
        programme pour le moment. Celui-ci permet sans doute d'accéder à
        d'autres informations.
      </p>
      <p>Nous comptons sur vous pour ouvrir celle-ci.</p>
      <p>
        <br />
      </p>
      <p>
        Ne nous décevez pas,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
