import { useEffect, useRef, useState } from "react";
import {
  StoryStatusApi,
  TIME_BETWEEN_CLUES_MS,
} from "../../../../shared/StoryElements";
import { getTimePercent } from "./ConstantsCluePopup";

export type ClueTimerInfos = {
  percent: number;
  secondsLeft: number;
  nextAvailable: boolean;
};

export function useClueTimer(storyStatus: StoryStatusApi): ClueTimerInfos {
  const [percent, setPercent] = useState<number>(-1);
  const [isSetUp, setIsSetUp] = useState<boolean>(false);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [previousClueDate, setPreviousClueDate] = useState<null | Date>(null);
  const interval = useRef<NodeJS.Timer | null>(null);

  const dates = {
    lastClue: new Date(storyStatus.lastClueDate),
    next: new Date(),
    current: new Date(),
  };

  if (previousClueDate === null || previousClueDate < dates.lastClue) {
    setPreviousClueDate(new Date(dates.lastClue));
    setPercent(-1);
    setIsSetUp(false);
  }

  const lastClueDate = dates.lastClue;
  const currentDate = dates.current;
  const nextDate = dates.next;

  dates.next.setTime(dates.lastClue.getTime() + TIME_BETWEEN_CLUES_MS);

  if (percent < 0 && !isSetUp) {
    setIsSetUp(true);
    setPercent(getTimePercent(dates.current, dates.lastClue));
    setSecondsLeft((dates.next.getTime() - dates.current.getTime()) / 1000);
  }

  useEffect(() => {
    if (percent < 100) {
      interval.current = setInterval(() => {
        var current = new Date();
        setPercent(getTimePercent(current, lastClueDate));
        setSecondsLeft((nextDate.getTime() - current.getTime()) / 1000);
      }, 1000);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
      interval.current = null;
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [percent, setPercent, setSecondsLeft]);

  return { percent, secondsLeft, nextAvailable: percent >= 100 };
}
