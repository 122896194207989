import { useEffect, useRef, useState } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { Popup } from "../../../../parts/Popup";
import Config from "../../../../Config";
import audioWhiteNoise from "../../../../audio/white_noise.mp3";

export default function FrequencyPlayer({
  realAudioUrl,
  audioDescription,
  playing,
  onLoopEnd,
}: {
  realAudioUrl: string | undefined;
  audioDescription?: string;
  playing: boolean;
  onLoopEnd?: () => void;
}) {
  const [showTranscript, setShowTranscript] = useState(false);
  const refHtmlRealAudio = useRef<HTMLAudioElement | null>(null);
  const refHtmlWhiteNoise = useRef<HTMLAudioElement | null>(null);

  const refAudioRealAudio = useRef<AudioNode | null>(null);
  const refAudioWhiteNoise = useRef<AudioNode | null>(null);

  const [currentMediaRecorder, setMediaRecorder] =
    useState<MediaRecorder | null>(null);

  const audioContextRef = useRef<AudioContext | null>(null);
  const mp3AudioDestinationRef = useRef<MediaStreamAudioDestinationNode | null>(
    null
  );

  useEffect(() => {
    if (audioContextRef.current === null) {
      if (Config.debug) {
        console.log(`Resetting audio context`);
        console.log(refHtmlRealAudio);
        console.log(refHtmlWhiteNoise);
        console.log(refAudioRealAudio);
        console.log(refAudioWhiteNoise);
        console.log(currentMediaRecorder);
        console.log(audioContextRef);
        console.log(mp3AudioDestinationRef);
        console.log(`--------`);
      }
      audioContextRef.current = new AudioContext();
    }

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current
          .close()
          .catch((err) => console.error("Error closing AudioContext:", err));
        audioContextRef.current = null;
      }
    };
  }, [audioContextRef]);

  const allRefsSounds = [refHtmlRealAudio, refHtmlWhiteNoise];
  //const audioContext: AudioContext | null = audioContextRef.current;

  useEffect(() => {
    if (Config.debug) {
      console.log(
        `In useEffect for mp3 destination: ${audioContextRef.current !== null}`
      );
    }

    if (audioContextRef.current !== null) {
      mp3AudioDestinationRef.current =
        audioContextRef.current.createMediaStreamDestination();
    }
  }, [audioContextRef]);

  function effectForAudio(
    html: React.MutableRefObject<HTMLAudioElement | null>,
    audioNode: React.MutableRefObject<AudioNode | null>
  ) {
    if (
      audioContextRef.current !== null &&
      html.current !== null &&
      (audioNode.current === null ||
        (audioNode.current as MediaElementAudioSourceNode).mediaElement !==
          html.current ||
        (audioNode.current as MediaElementAudioSourceNode).context !==
          audioContextRef.current)
    ) {
      var newHtmlElement = html.current;
      if (
        audioNode.current !== null &&
        (audioNode.current as MediaElementAudioSourceNode).context !==
          audioContextRef.current
      ) {
        const oldHtmlElement = html.current;
        newHtmlElement = oldHtmlElement.cloneNode(true) as HTMLAudioElement;
        oldHtmlElement.replaceWith(newHtmlElement);
        html.current = newHtmlElement;
      }
      audioNode.current =
        audioContextRef.current.createMediaElementSource(newHtmlElement);
    }
  }

  useEffect(() => {
    var html = refHtmlRealAudio;
    var audioNode = refAudioRealAudio;

    effectForAudio(html, audioNode);

    if (refHtmlRealAudio.current && realAudioUrl) {
      refHtmlRealAudio.current.src = realAudioUrl;
      refHtmlRealAudio.current.onseeked = (_) => {
        if (onLoopEnd) {
          onLoopEnd();
        }
      };
    }

    return () => audioNode.current?.disconnect();
  }, [audioContextRef, refHtmlRealAudio, realAudioUrl]);

  useEffect(() => {
    var html = refHtmlWhiteNoise;
    var audioNode = refAudioWhiteNoise;

    effectForAudio(html, audioNode);
  }, [audioContextRef, refHtmlWhiteNoise]);

  function stopSound() {
    allRefsSounds.forEach((ref) => {
      if (ref.current != null && !ref.current.paused) {
        ref.current.pause();
      }
    });

    if (
      audioContextRef.current !== null &&
      audioContextRef.current.state === "running"
    ) {
      //audioContext.suspend();
    }
  }

  function linkSound(mp3AudioSource: AudioNode | null) {
    if (mp3AudioSource === null) {
      console.error("Error in linking: Source not initialized");
      return;
    }

    if (mp3AudioDestinationRef.current === null) {
      console.error("Error in linking: Destination not initialized");
      return;
    }

    if (audioContextRef.current === null) {
      console.error("Error in linking: Context not initialized");
      return;
    }

    mp3AudioSource.connect(mp3AudioDestinationRef.current);
    mp3AudioSource.connect(audioContextRef.current.destination);

    const nextMediaRecorder = new MediaRecorder(
      mp3AudioDestinationRef.current.stream
    );
    nextMediaRecorder.ondataavailable = (ev) => {
      nextMediaRecorder.start();
    };
    nextMediaRecorder.start();

    setMediaRecorder(nextMediaRecorder);
  }

  useEffect(() => {
    if (Config.debug) {
      console.log(
        `Entering in effect : ${audioContextRef.current?.state}, playing: ${playing}`
      );
    }

    if (playing) {
      if (
        audioContextRef.current?.state === "suspended" ||
        audioContextRef.current?.state === "running"
      ) {
        if (Config.debug) {
          console.log(`Resuming context`);
        }
        audioContextRef.current
          ?.resume()
          .catch((e) => console.log("Error: " + e));
        //audioContextRef.current = new AudioContext();
      }

      var toPlay = { refHtml: refHtmlWhiteNoise, refAudio: refAudioWhiteNoise };

      if (realAudioUrl) {
        toPlay = { refHtml: refHtmlRealAudio, refAudio: refAudioRealAudio };
      }

      if (toPlay.refHtml.current !== null) {
        toPlay.refHtml.current
          .play()
          .catch((r) => console.error("Error on play => " + r));
      }
      linkSound(toPlay.refAudio.current);
    } else if (!playing) {
      stopSound();
    }

    return () => {
      stopSound();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  if (currentMediaRecorder && currentMediaRecorder.state !== "recording") {
    currentMediaRecorder.start();
  }

  return (
    <>
      <div
        className="audio-player-bar"
        style={{
          marginTop: "2rem",
          height: "12vh",
          width: "80vw",
          margin: "2rem auto 1em auto",
        }}
      >
        <audio
          ref={refHtmlRealAudio}
          src={realAudioUrl}
          loop={true}
          onSeeked={(e) => {
            if (onLoopEnd) {
              onLoopEnd();
            }
          }}
        />
        <audio ref={refHtmlWhiteNoise} src={audioWhiteNoise} loop={true} />
        {currentMediaRecorder ? (
          <LiveAudioVisualizer
            mediaRecorder={currentMediaRecorder}
            width={600}
            height={75}
            barWidth={5}
            gap={2}
            barColor={"#f76565"}
            maxDecibels={100}
            fftSize={512}
          />
        ) : (
          <></>
        )}
      </div>
      {audioDescription && playing ? (
        <>
          <button
            className="button is-dark"
            onClick={(e) => {
              e.preventDefault();
              setShowTranscript(true);
              if (onLoopEnd && !showTranscript) {
                onLoopEnd();
              }
            }}
          >
            Lire Transcription
          </button>
          <Popup popupStateEditor={[showTranscript, setShowTranscript]}>
            <h3>Transcription</h3>
            {audioDescription}
          </Popup>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
