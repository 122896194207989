import "../styles/A4.scss";
import "../styles/A4unset.scss";
import "../styles/transcriptStyle.scss";
import "./simpleImage.scss";
import { useNavigate } from "react-router";

export function UsbImageFile({
  src,
  alt,
  direction,
}: {
  src: string;
  alt: string;
  direction?: "vertical" | "horizontal" | "fit" | undefined;
}) {
  const navigate = useNavigate();

  return (
    <div className="">
      <div className={"image-document " + (direction ? `is-${direction}` : "")}>
        <img src={src} alt={alt} />
      </div>
      <div className="floating-bottom is-fullwidth">
        <button
          className="button is-medium"
          onClick={() => {
            if (window.history.length > 1) {
              navigate(-1); // Go back to the previous page
            } else {
              navigate("/"); // No history, go to home
            }
          }}
        >
          Retour
        </button>
      </div>
    </div>
  );
}
