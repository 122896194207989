import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ToulouseMessage3GoAssezat() {
  return (
    <BaseMessage title="Vous êtes étonnant">
      <p>Votre talent nous étonne.</p>
      <p>
        Maintenant que vous avez déchiffré ce message vous allez devoir
        l'utiliser pour suivre la trace de l'agent HENRI.
      </p>
      <p>
        Nous supposons que cet "indicateur" cherchait en vérité à l'isoler avant
        de l'emmener dans un guet-apens.
      </p>
      <p>
        Nous supposons que suivre le message va vous permettre de décoder leur
        conversation. Vous pouvez également le retrouvez dans la section
        "Messages"
      </p>
      <p>Transmettez-le nous dès que possible.</p>
      <p>
        <br />
      </p>
      <p>
        Continuez,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
