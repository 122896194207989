import { ReactElement, useState } from "react";
import { StoryStatusApi, StoryStepApi } from "../../../../shared/StoryElements";
import {
  AskForNewClue,
  ClueButton,
  LockedClueButton,
  NextClueButton,
} from "./ElementsCluePopup";
import { ClueTimerInfos, useClueTimer } from "./TimerCluePopup";
import { SwitchTransition } from "react-transition-group";
import { FadeTransition } from "../../../../parts/Transitions";
import { useTimerPercent } from "../../../../tools/TimeTools";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/ReduxStore";
import { StatusThunk } from "../../../../redux/status/StatusThunk";
import Config from "../../../../Config";
import { useNavigate } from "react-router";
import { onSuccessNavigation } from "../../../code/CodeUtils";

function nextClueSentence(timerInfos: ClueTimerInfos) {
  return (
    "Prochain indice " +
    (timerInfos.nextAvailable
      ? "disponible"
      : `dans ${String(Math.trunc(timerInfos.secondsLeft / 60)).padStart(
          2,
          "0"
        )}:${String(Math.trunc(timerInfos.secondsLeft % 60)).padStart(2, "0")}`)
  );
}

export function RealCluePopup(props: {
  storyStatus: StoryStatusApi;
  step: StoryStepApi;
  hidePopup?: () => void;
}) {
  const storyStatus = props.storyStatus;
  const step = props.step;
  const navigate = useNavigate();
  const timerInfos: ClueTimerInfos = useClueTimer(storyStatus);
  const [askingForNewClue, setAskingForNewClue] = useState(false);

  // For alternative path
  const dispatch = useDispatch<AppDispatch>();
  const [confirmingAlternative, setConfirmingAlternative] = useState(false);
  const [alternativeEnterDate, setAlternativeEnterDate] = useState(new Date());
  const percentDurationSec = Config.debug ? 5 : 20;
  const percentAlternative = useTimerPercent(
    alternativeEnterDate,
    percentDurationSec / 60,
    200
  );
  const alternativeRemainingSeconds = Math.round(
    ((100 - percentAlternative) / 100) * percentDurationSec
  );

  // For clues
  const [prevClueIndex, setPrevClueIndex] = useState(storyStatus.clueIndex);
  const [currentClueIndex, setCurrentClueIndex] = useState(
    storyStatus.clueIndex
  );

  if (prevClueIndex !== storyStatus.clueIndex) {
    setPrevClueIndex(storyStatus.clueIndex);
    setCurrentClueIndex(storyStatus.clueIndex);
  }

  const buttons: ReactElement[] = [];
  for (var i = 0; i <= step.maxClues; i++) {
    if (i <= storyStatus.clueIndex) {
      const j = i;
      buttons.push(
        ClueButton(i, i === currentClueIndex, () => setCurrentClueIndex(j))
      );
    } else if (i === storyStatus.clueIndex + 1) {
      buttons.push(NextClueButton(timerInfos, i, setAskingForNewClue));
    } else {
      buttons.push(LockedClueButton(i));
    }
  }

  return (
    <div className="popup" key={storyStatus.clueIndex}>
      {!confirmingAlternative ? (
        <>
          <h2>{step.name}</h2>
          <SwitchTransition mode="out-in">
            <FadeTransition key={currentClueIndex.toString()}>
              <p>
                {currentClueIndex === 0 ||
                currentClueIndex > storyStatus.clues.length
                  ? step.description
                  : storyStatus.clues[currentClueIndex - 1].hint}
              </p>
            </FadeTransition>
          </SwitchTransition>
          <SwitchTransition mode="out-in">
            <FadeTransition
              key={askingForNewClue ? "new-clue" : "button-list"}
              //nodeRef={nodeRef}
            >
              <div className="popup-button-box">
                {askingForNewClue ? (
                  <AskForNewClue
                    isLast={storyStatus.clueIndex === step.maxClues - 1}
                    goBack={(v) => {
                      setAskingForNewClue(false);
                    }}
                  />
                ) : (
                  <>
                    <p className="is-family-monospace">
                      {storyStatus.clueIndex >= step.maxClues
                        ? "Solution Disponible"
                        : nextClueSentence(timerInfos)}
                    </p>
                    <div className="field has-addons">{buttons}</div>
                  </>
                )}
              </div>
            </FadeTransition>
          </SwitchTransition>
          {storyStatus.step?.hasAlternativePath ? (
            <>
              <div className="popup-button-box">
                <button
                  className="button is-fullwidth is-family-monospace available"
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmingAlternative(true);
                    setAlternativeEnterDate(new Date());
                  }}
                >
                  Utiliser Itinéraire Alternatif
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <h2>
            {step.name}
            <span style={{ fontSize: "80%" }}> (Chemin Alternatif)</span>
          </h2>
          <SwitchTransition mode="out-in">
            <FadeTransition key={currentClueIndex.toString()}>
              <p>
                Vous pouvez utiliser un chemin alternatif en cas de fermeture au
                public de certaines sections de cette étape. <br />
              </p>
            </FadeTransition>
          </SwitchTransition>
          <div>
            <div style={{ marginTop: "1em" }}>
              <p style={{ fontSize: "70%", textAlign: "center" }}>
                <b>
                  Ce choix est définitif, vous ne pourrez plus revenir sur la
                  route principale.
                </b>
              </p>
              <button
                className="button is-fullwidth is-family-monospace is-active"
                onClick={async (e) => {
                  e.preventDefault();
                  const resp = await dispatch(StatusThunk.goAlternativePath());
                  if (resp.payload.step) {
                    onSuccessNavigation(resp.payload, navigate);
                    if (props.hidePopup) {
                      props.hidePopup();
                    }
                  }
                }}
                disabled={percentAlternative < 100}
              >
                {percentAlternative >= 100
                  ? "Confirmer la route alternative"
                  : `Disponible dans ${alternativeRemainingSeconds} seconde${
                      alternativeRemainingSeconds > 1 ? "s" : ""
                    }`}
              </button>
            </div>
            <div className="popup-button-box">
              <button
                className="button is-fullwidth is-family-monospace available"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmingAlternative(false);
                }}
              >
                Retour à la route principale
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
