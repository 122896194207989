import { DocumentPage } from "../../DocumentsElements";
import "../../styles/A4.scss";
import "../../styles/A4unset.scss";
import map from "./CarteToulouse.png";

export function ToulouseMapDocument() {
  return (
    <DocumentPage>
      <img src={map} alt="Carte de Toulouse" />
    </DocumentPage>
  );
}
