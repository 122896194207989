import { Expand } from "../../../parts/Expand";
import { BackButtonFlex } from "../../../parts/BackButton";
import "./ToulouseFinal.scss";
import { RootState } from "../../../redux/ReduxStore";
import { useSelector } from "react-redux";
import { StoryConstants } from "../../../shared/StoryConstants";
import { useNavigate } from "react-router";
import "./ToulouseFinal.scss";
import { useState } from "react";
import { useTimerPercent } from "../../../tools/TimeTools";
import Config from "../../../Config";
import { ToulouseFinalSquare } from "./ToulouseFinalSquare";
import {
  ToulouseFinalSizeSelector,
  ToulouseFinalTopButton,
} from "./ToulouseFinalSquareTopParts";
import { CodeApp } from "../../code/CodeApp";
import { CodeFormNormal } from "../../code/code_types/CodeFormNormal";

export function ToulouseFinalApp() {
  const statusState = useSelector((state: RootState) => state.status.status);
  const navigate = useNavigate();

  const [squareSize, setSquareSize] = useState<number>(1);
  const [loading, setLoading] = useState<{ status: boolean; date: Date }>({
    status: false,
    date: new Date(),
  });

  const [showCodeInput, setShowCodeInput] = useState(false);

  const percent = useTimerPercent(loading.date, Config.debug ? 0.04 : 0.4, 100);

  function editSquareSize(diff: number) {
    const newSize = squareSize + diff;
    setSquareSize(newSize < 1 ? 1 : newSize > 9 ? 9 : newSize);
    setLoading({ status: false, date: new Date() });
  }

  if (
    !statusState ||
    statusState.story?.city !== StoryConstants.City.TOULOUSE ||
    !statusState.step?.index ||
    statusState.step?.index < StoryConstants.Steps.Toulouse.LE_PONT
  ) {
    navigate("/home");
  }

  return (
    <>
      <div className="text-center leftFadeIn toulouse-final">
        <div className="top-part">
          <ToulouseFinalSizeSelector
            disabled={showCodeInput}
            squareSize={squareSize}
            editSquareSize={editSquareSize}
          />
          <ToulouseFinalTopButton
            showOpenButton={loading.status && percent >= 100}
            showCodeInput={showCodeInput}
            setShowCodeInput={setShowCodeInput}
            isLoading={loading.status}
            percent={percent}
            onLoadingClick={() =>
              setLoading({ status: true, date: new Date() })
            }
          />
        </div>
        {showCodeInput ? (
          <CodeApp
            specificCode={{
              isIncluded: true,
              isCentered: true,
              type: "text",
              placeholder: "Rendez-Vous",
              formElement: CodeFormNormal,
              preformatCode: (c) => squareSize.toString() + "_" + c,
            }}
          />
        ) : (
          <>
            <div className="text-center">
              <p className="is-family-monospace" style={{ fontWeight: "900" }}>
                {loading.status
                  ? Math.round(percent > 100 ? 100 : percent).toString() + "%"
                  : "\u00A0"}
              </p>
            </div>

            <ToulouseFinalSquare
              squareSize={squareSize}
              isLoading={loading.status}
              percent={percent}
            />
          </>
        )}
      </div>
      <Expand />
      <BackButtonFlex />
    </>
  );
}

export default ToulouseFinalApp;
