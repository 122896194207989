import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ToulouseMessage6CleDeverrouillee() {
  return (
    <BaseMessage title="Clé Déverrouillée">
      <p>
        Félicitations, <br />
        le code était le bon nous avons pu déverrouiller la clé USB.
      </p>
      <p>
        Seulement elle ne contient quasiment rien, juste des fichiers et un
        autre programme étrange. Celui-ci contient peut-être les véritables
        données que voulait récupérer notre agent.
      </p>
      <p>
        Nous vous avons donné l'accès à tous les éléments de la clé mais nous
        pensons que les fichiers ressemblent à des instructions pour se
        retrouver.
      </p>
      <p>
        Intéressez vous aux documents et nous verrons plus tard pour le
        programme.
      </p>
      <p>
        <br></br>
        Suivez la piste et triomphez !<br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
