import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ParisMessage7DernierFichier() {
  return (
    <BaseMessage title="Dernier Fichier">
      <p>Encore bravo.</p>
      <p>
        Vous arrivez au bout de cette piste. La sixième photo doit sûrement nous
        permettre d'accéder au dernier fichier.
      </p>
      <p>
        On dirait que celui-ci contient du texte, il nous faut un code à 4
        chiffres pour l'ouvrir.
      </p>
      <p>
        <br />
      </p>
      <p>
        Ne vous arrêtes pas maintenant,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
