import { StoryConstants } from "../../shared/StoryConstants";
import { CodeFormLinks } from "./code_types/CodeFormLinks";
//import { CodeFormGPS } from "./code_types/CodeFormGPS";
import { CodeToulouseFontaine } from "./code_types/CodeToulouseFontaine";
import { CodeToulouseHotel } from "./code_types/CodeToulouseHotel";
import { CodeToulouseLastSms } from "./code_types/CodeToulouseLastSms";
import { CodeToulouseSymboles } from "./code_types/CodeToulouseSymboles";
//import { CodeRendezVous } from "./code_types/CodeRendezVous";
import { CodeFrequenciesToulouse } from "./code_types/frequencies_toulouse/CodeFrequenciesToulouse";
import { CodeFormInfos } from "./CodeFormInfos";

export function CodeListToulouse1(
  stepIdx: number,
  baseInfos: CodeFormInfos
): CodeFormInfos | undefined {
  switch (stepIdx) {
    case StoryConstants.Steps.Toulouse.FIND_ALPHA:
      return {
        ...baseInfos,
        minLength: 3,
        placeholder: "Point ALPHA",
      };
    case StoryConstants.Steps.Toulouse.FIND_FOUNTAIN:
      return {
        ...baseInfos,
        type: "text",
        formElement: CodeToulouseFontaine,
        subheader: "Rendez-vous au point ALPHA et cherchez le code",
      };
    case StoryConstants.Steps.Toulouse.TRINITE_TO_ASSEZAT:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeToulouseHotel,
        header: "La Porte",
        subheader: "Suivez le message et donnez-nous le nouveau code.",
      };
    case StoryConstants.Steps.Toulouse.ECHARPE_TABAC:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeToulouseSymboles,
        header: "La clé USB",
        subheader: "⬅🧣⬆🚬➡🔍6️⃣🗽",
        isCentered: false,
      };
    case StoryConstants.Steps.Toulouse.LE_PONT:
      return {
        ...baseInfos,
        placeholder: "Lieu de Rendez-Vous",
        header: "La Piste",
        subheader:
          "Utilisez le premier document sur la clé pour trouver le lieu de rendez-vous",
        formElement: CodeFormLinks,
        links: [
          {
            to: "/document/usb_file1",
            str: "Clé USB - Fichier 1",
          },
        ],
      };
    case StoryConstants.Steps.Toulouse.PISTE_PHOTO:
      return {
        ...baseInfos,
        placeholder: "Rue du quatrième fichier",
        header: "La Piste",
        subheader:
          "Nous sommes en train de perdre la piste. Utilisez les images pour trouver la rue du quatrième fichier.",
        formElement: CodeFormLinks,
        links: [
          {
            to: "/document/usb_file2",
            str: "Clé USB - Fichier 2",
          },
          {
            to: "/document/usb_file3",
            str: "Clé USB - Fichier 3",
          },
          {
            to: "/document/usb_file4",
            str: "Clé USB - Fichier 4",
          },
        ],
      };
    case StoryConstants.Steps.Toulouse.FREQUENCES:
    case StoryConstants.Steps.Toulouse.RECHERCHE_PASSERELLE:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeFrequenciesToulouse,
        header: "Fréquences",
        subheader: "Sélectionner une fréquence à déchiffrer",
        isCentered: false,
      };
    case StoryConstants.Steps.Toulouse.SQUARE_END:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeFrequenciesToulouse,
        header: "Fréquences",
        subheader: "Ré-écouter les fréquences pour ouvrir final.exe",
        isCentered: false,
      };
    case StoryConstants.Steps.Toulouse.ALTERNATIVE_PONT:
      return {
        ...baseInfos,
        type: "text",
        header: "Dernier SMS",
        formElement: CodeToulouseLastSms,
        subheader: "Où l'informatrice a-t-elle rencontré Henri ?",
      };
  }

  return undefined;
}
