import { useState } from "react";

export function ToulouseFinalSquare({
  squareSize,
  isLoading,
  percent,
}: {
  squareSize: number;
  isLoading: boolean;
  percent: number;
}) {
  var [coordinatesCharacters, setCoordinatesCharacters] = useState<{
    [x: number]: { [y: number]: string };
  }>({});
  const [lastPercent, setLastPercent] = useState(0);

  const presetCoordinates: {
    [idx: number]: {
      [x: number]: { [y: number]: string };
    };
  } = {
    4: { 2: { 3: "¤" }, 0: { 1: "↘" } },
    6: { 2: { 3: "↘", 4: "↘" } },
    7: { 0: { 2: "↖" }, 1: { 1: "↖" } },
    8: { 0: { 1: "↙" }, 2: { 4: "↘" }, 4: { 2: "↘", 4: "↗" } },
    9: { 3: { 0: "↖", 2: "↘" }, 4: { 2: "¤" } },
  };
  if (
    isLoading &&
    (lastPercent < Math.round(percent) + 1 ||
      lastPercent > Math.round(percent) + 1.2)
  ) {
    const nbCharacters = Math.round(squareSize / 2);
    const allChars = ["↖", "↘", "↗", "↙", "¤"];
    var newCoords: { [x: number]: { [y: number]: string } } = {};

    if (Math.round(percent) < 100 || !presetCoordinates[squareSize])
      new Array(nbCharacters).fill(0).forEach((_) => {
        const x = Math.floor(Math.random() * squareSize);
        const y = Math.floor(Math.random() * squareSize);
        if (!newCoords[x]) {
          newCoords[x] = {};
        }
        newCoords[x][y] = allChars[Math.floor(Math.random() * allChars.length)];
      });
    else {
      newCoords = presetCoordinates[squareSize];
    }

    setCoordinatesCharacters(newCoords);
    setLastPercent(Math.round(percent) + 1);
  } else if (!isLoading && lastPercent !== 0) {
    setCoordinatesCharacters({});
    setLastPercent(0);
  }

  return (
    <div className={"final-grid grid-" + squareSize}>
      {new Array(squareSize).fill(null).map((_, y) => {
        return new Array(squareSize).fill(null).map((_, x) => {
          return (
            <div className="square" key={x * squareSize + y}>
              <p>
                {coordinatesCharacters[x] && coordinatesCharacters[x][y]
                  ? coordinatesCharacters[x][y]
                  : "\u00A0"}
              </p>
            </div>
          );
        });
      })}
    </div>
  );
}
