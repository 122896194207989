import { useCallback, useEffect, useState } from "react";
import { CodeFormProps } from "../CodeFormInfos";
import { OneCodeButton } from "../elements/OneCodeButton";

export function CodeFormNumberAndMessage(p: CodeFormProps) {
  const codeLength =
    p.formInfos.type === "number" && p.formInfos.max ? p.formInfos.max : 4;
  const [currentCodes, setCurrentCodes] = useState<number[]>(
    new Array(codeLength).fill(0)
  );
  const [codeHasChanged, setCodeHasChanged] = useState<boolean>(false);

  const checkCurrentCode = useCallback(() => {
    const codeToCheck = currentCodes.reduce((p, curr) => p + curr, "");
    if (codeToCheck !== "0000" || codeHasChanged) {
      setCodeHasChanged(true);
    }
  }, [currentCodes, codeHasChanged]);

  useEffect(() => {
    checkCurrentCode();
  }, [currentCodes, checkCurrentCode]);

  function setCodeIdx(idx: number, diff: number) {
    const value = currentCodes[idx] + diff;
    var realValue = value < 0 ? 9 : value > 9 ? 0 : value;
    var nextCodes = [...currentCodes];
    nextCodes[idx] = realValue;
    setCurrentCodes(nextCodes);
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          checkCurrentCode();
        }}
        style={{ marginTop: 0 }}
      >
        <div
          className="bottom-part"
          style={{ marginTop: 0, marginBottom: "3rem" }}
        >
          <h2>Message DANI</h2>
          <div>
            <div
              className="text-encart is-family-monospace text-center"
              style={{ fontSize: "105%" }}
            >
              {p.formInfos.placeholder}
            </div>
          </div>
        </div>
        {p.errorMessage ? (
          <h2 className="is-family-monospace login-tooltip is-nok">
            {p.errorMessage}
          </h2>
        ) : (
          <></>
        )}
        <div
          className="field code-app-field inline-buttons"
          style={{ marginBottom: "4rem" }}
        >
          <div>
            <div className="control">
              <button
                className="button is-fullwidth is-family-monospace is-active is-left"
                disabled={p.correct}
                type="submit"
              >
                ↳
              </button>
            </div>
          </div>
          <div className="core-buttons">
            {new Array(codeLength).fill(null).map((_, i) => {
              return (
                <OneCodeButton
                  correct={p.correct}
                  setCode={(x) => setCodeIdx(i, x)}
                  value={currentCodes[i].toString()}
                  key={i}
                />
              );
            })}
          </div>
          <div>
            <div className="control">
              <button
                className="button is-fullwidth is-family-monospace is-active is-right"
                disabled={p.correct}
                type="submit"
              >
                ↲
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className="control">
        <button
          className="button is-fullwidth is-family-monospace is-active"
          onClick={(e) => {
            e.preventDefault();
            const codeToCheck = currentCodes.reduce((p, curr) => p + curr, "");
            p.checkCode(codeToCheck);
          }}
        >
          VALIDER
        </button>
      </div>
    </>
  );
}

/*

        */
