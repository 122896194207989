import { useStoryStatus } from "../../../redux/status/StatusSlice";
import { DocumentPage } from "../../DocumentsElements";
import "../../styles/A4.scss";
import "../../styles/A4unset.scss";
import "./transcriptStyle.scss";

export interface TranscriptionElement {
  duration: number;
  type: string;
  lines?: Array<string>;
}

export function LyonTranscriptionDocument() {
  const storyStatus = useStoryStatus();
  const startDateStr: string | undefined = storyStatus?.startDate;

  if (!startDateStr) {
    return <></>;
  }

  const baseDate = new Date(startDateStr);
  baseDate.setDate(baseDate.getDate() - 8);
  const transcriptionDate = new Date();
  transcriptionDate.setDate(baseDate.getDate() + 2);

  const lines: Array<TranscriptionElement> = [
    { duration: 5, type: "Bruit Blanc" },
    {
      duration: 15,
      type: "Voix Modifiée",
      lines: [
        // "Bonjour, nous sommes la compagnie. N'enregistrez pas ce message. Prenez des notes et trouvez notre secret dans ce document de communication. ",
        "Bonjour HENRI. N'enregistrez pas ce message. L'heure est venue de nous rencontrer. Prenez une carte et tracez-y mes indications. " +
          "Je ne me répéterais pas, c'est votre seule chance de trouver le point ALPHA.",
      ],
    },
    { duration: 5, type: "Silence" },
    {
      duration: 28,
      type: "Voix Modifiée",
      lines: [
        //"Dans un premier temps, je vous conseille d'acheter cet excellent jeu dans lequel vous découvrirez une ville.",
        //"Ceci est une mission dont vous êtes capable aisément grâce aux liens de la boutique sur internet.",
        "Dans un premier temps, je vous conseille de chercher un boulevard au Nord de la ville sur lequel vous marquerez l'arrêt.",
        "De là, tracez une ligne droite jusqu'à la gare qui surplombe la mouche.",
      ],
    },
    { duration: 3, type: "Silence" },
    {
      duration: 16,
      type: "Voix Modifiée",
      lines: [
        //"Une fois fait, cherchez le sens de ces documents.",
        "Une fois fait, cherchez l'axe déjà existant entre deux saints.",
        "Tracez-le et ne vous arrêtez sous aucun prétexte !",
      ],
    },
    { duration: 4, type: "Silence" },
    {
      duration: 12,
      type: "Voix Modifiée",
      lines: [
        //"Continuer jusqu'à avoir une idée de ce qui peut s'y cacher.",
        "Continuez jusqu'à la croisée des chemins. Le point ALPHA se trouve à 500m au Nord.",
      ],
    },
    { duration: 3, type: "Silence" },
    {
      duration: 50,
      type: "Voix Modifiée",
      lines: [
        //"Je vous ai laissé le prochain message dans d'autres communications.",
        "Je vous ai laissé le prochain message à proximité de la première des gardiennes.",
      ],
    },
    { duration: 5, type: "Silence" },
    {
      duration: 10,
      type: "Voix Modifiée",
      lines: ["Ne laissez aucune trace.", "Bon courage."],
    },
  ];
  var timecodeCurrent = 0;
  return (
    <DocumentPage extraClass="transcript">
      <div className="transcript-header">
        <h1>Message vocal du {baseDate.toLocaleDateString("fr")}</h1>
        <h2>Retranscription du {transcriptionDate.toLocaleDateString("fr")}</h2>
      </div>
      <div className="transcript-main">
        {lines.map((elem) => {
          const returnElem = (
            <div key={timecodeCurrent} className="transcriptElement">
              <p className="strong">
                <span className="timecode">
                  {Math.floor(timecodeCurrent / 60).toLocaleString("fr-FR", {
                    minimumIntegerDigits: 2,
                  })}
                  :
                  {(timecodeCurrent % 60).toLocaleString("fr-FR", {
                    minimumIntegerDigits: 2,
                  })}
                </span>
                <span className="dash">—</span>
                <span className="type">{elem.type}</span>
                <span></span>
              </p>
              {elem.lines ? (
                elem.lines.map((line) => {
                  return <p className="line">{line}</p>;
                })
              ) : (
                <></>
              )}
            </div>
          );

          timecodeCurrent += elem.duration;

          return returnElem;
        })}
        <p className="strong">Fin de l'enregistrement</p>
      </div>
      <div className="transcript-footer">
        <p>La Compagnie · Document Interne F158403365</p>
      </div>
    </DocumentPage>
  );
}
