import { StoryConstants } from "../../../shared/StoryConstants";
import { CodeRendezVousPage } from "../../code/code_types/CodeRendezVous";
import { LinkInfosWithElement } from "../../LinkListPage";
import { LyonMessage1Alpha } from "../pages_lyon/LyonMessage1_Alpha";
import { LyonMessage2Gardiennes } from "../pages_lyon/LyonMessage2_Gardiennes";
import { LyonMessage3Perchee } from "../pages_lyon/LyonMessage3_Perchee";
import { LyonMessage4GPS } from "../pages_lyon/LyonMessage4_GPS";
import { LyonMessage5CleUsb } from "../pages_lyon/LyonMessage5_CleUsb";
import { LyonMessage6Stars } from "../pages_lyon/LyonMessage6_Stars";
import { LyonMessage7LaFin } from "../pages_lyon/LyonMessage7_LaFin";

export function getLyon1Messages(stepIdx: number): LinkInfosWithElement[] {
  const toReturn: LinkInfosWithElement[] = [];

  if (stepIdx >= StoryConstants.Steps.Lyon.FIND_STATUE) {
    toReturn.push({
      infos: {
        url: "premierMessage",
        text: "Premier Message",
      },
      element: <LyonMessage1Alpha />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Lyon.DAME_PERCHEE) {
    toReturn.push({
      infos: {
        url: "messageDechiffre",
        text: "Message de la Gardienne",
      },
      element: <LyonMessage2Gardiennes />,
    });

    toReturn.push({
      infos: {
        url: "secondMessage",
        text: "Second Message",
      },
      element: <LyonMessage3Perchee />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Lyon.RENDEZ_VOUS) {
    toReturn.push({
      infos: {
        url: "rendezVous",
        text: "Le Rendez-vous",
      },
      element: <LyonMessage4GPS />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Lyon.LA_PLACE) {
    toReturn.push({
      infos: {
        url: "laConversation",
        text: "La Conversation",
      },
      element: <CodeRendezVousPage />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Lyon.DEVOIR_MUT) {
    toReturn.push({
      infos: {
        url: "cleUsb",
        text: "La Clé USB",
      },
      element: <LyonMessage5CleUsb />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Lyon.STARS) {
    toReturn.push({
      infos: {
        url: "stars",
        text: "Dernière Tâche",
      },
      element: <LyonMessage6Stars />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Lyon.FIRST_END) {
    toReturn.push({
      infos: {
        url: "laFin",
        text: "Remerciements",
      },
      element: <LyonMessage7LaFin />,
    });
  }

  return toReturn;
}
