import { useState } from "react";
import { CodeFormProps } from "../CodeFormInfos";
import { ParisTextsMessage } from "./CodeParisFontaine";

export function CodeFormFileToFill(p: CodeFormProps) {
  const baseText = ParisTextsMessage.join("\n") + "\n";
  const [currentCode, setCurrentCode] = useState<string>("");

  function updateTextArea(fullText: string) {
    if (!fullText.startsWith(baseText)) {
      setCurrentCode("");
      return;
    }
    console.log(fullText);
    console.log(fullText.substring(baseText.length));

    setCurrentCode(fullText.substring(baseText.length));
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        p.checkCode(currentCode);
      }}
      style={{ marginBottom: "15vh" }}
    >
      {p.errorMessage ? (
        <h2 className="is-family-monospace login-tooltip is-nok">
          {p.errorMessage.split("\n").map((elmt, idx) => (
            <span key={idx}>
              {elmt}
              <br />
            </span>
          ))}
        </h2>
      ) : (
        <></>
      )}
      <div className="field code-app-field">
        <div className="control">
          <textarea
            className="input is-family-monospace"
            name="code"
            value={baseText + currentCode}
            onChange={(e) => updateTextArea(e.target.value)}
            {...p.formInfos}
            style={{ height: "unset" }}
            onFocus={(args) => {
              const target = args.target;
              target.selectionStart = target.selectionEnd = target.value.length;
            }}
            onKeyDown={(event) => {
              if (event.which === 13) {
                if (!event.repeat) {
                  p.checkCode(currentCode);
                }

                event.preventDefault(); // Prevents the addition of a new line in the text field
              }
            }}
          />
        </div>
        <div className="control">
          <button className="button is-fullwidth is-family-monospace is-active">
            VALIDER
          </button>
        </div>
      </div>
    </form>
  );
}
