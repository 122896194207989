export function RuleBox() {
  return (
    <p
      style={{
        fontSize: "0.95em",
        border: "1px solid #263f61",
        background: "#fafafa",
        padding: "0.4em",
      }}
    >
      Avant de commencer l'enquête vous devrez créer un compte sur le site
      internet en scannant le code en bas de ce courrier.{" "}
      <b>
        Utilisez cet espace en ligne pour valider le point de rendez-vous et
        accéder à la suite de l'histoire
      </b>
      . Vous pouvez également y demandez des indices à chaque étape de
      l'aventure.
    </p>
  );
}
