import { PropsWithChildren } from "react";

export function BasicModal({
  children,
  popupVisible,
  setPopupVisible,
}: PropsWithChildren<{
  popupVisible: boolean;
  setPopupVisible: (x: boolean) => void;
}>) {
  const crossSize = "2.5rem";
  const modalSizeStyle = (size: string | number): React.CSSProperties => {
    return {
      height: size,
      width: size,
      minWidth: size,
      minHeight: size,
      maxHeight: size,
      maxWidth: size,
    };
  };

  return (
    <div className={"modal " + (popupVisible ? "is-active" : "")}>
      <div
        className="modal-background"
        onClick={(e) => {
          e.preventDefault();
          setPopupVisible(!popupVisible);
        }}
      ></div>
      <div className="modal-content">
        <div className="box">{children}</div>
      </div>
      <button
        className="modal-close button is-rounded navbar-button"
        aria-label="close"
        onClick={(e) => {
          e.preventDefault();
          setPopupVisible(!popupVisible);
        }}
        style={{
          fontWeight: 800,
          ...modalSizeStyle(crossSize),
        }}
      ></button>
    </div>
  );
}
