import { StoryConstants } from "../../shared/StoryConstants";
import { CodeFormInfos } from "./CodeFormInfos";
import { CodeFormFileToFill } from "./code_types/CodeFormFileToFill";
import { CodeFormLinkAndImage } from "./code_types/CodeFormLinkAndImage";
import { CodeFormLinks } from "./code_types/CodeFormLinks";
import { CodeFormNormal } from "./code_types/CodeFormNormal";
import { CodeFormNumberAndMessage } from "./code_types/CodeFormNumberAndMessage";
import { CodeFormParisFinal } from "./code_types/CodeFormParisFinal";
import { CodeParisStatue } from "./code_types/CodeParisFontaine";
import { SixCodesForm } from "./code_types/SixCodesForm";
import { CodeFrequenciesParis } from "./code_types/frequencies_paris/CodeFrequenciesParis";
import { CodeParisSymboles } from "./code_types/symboles_paris/CodeParisSymboles";

export function CodeListParis1(
  stepIdx: number,
  baseInfos: CodeFormInfos
): CodeFormInfos | undefined {
  switch (stepIdx) {
    case StoryConstants.Steps.Paris.FIND_ALPHA:
      return {
        ...baseInfos,
        minLength: 3,
        placeholder: "Point ALPHA",
      };
    case StoryConstants.Steps.Paris.PARC_FETE:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeParisSymboles,
        header: "Application Secrète",
        subheader: "Ces symboles permettent sans doute d'ouvrir l'application",
        isCentered: false,
      };
    case StoryConstants.Steps.Paris.GO_BIBLI:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeFormNumberAndMessage,
        header: "Application Secrète",
        placeholder: "1️⃣🧍​.1️⃣⬅️.​1️⃣➡️​.⬆️​🔎​📖​",
        max: 4,
        subheader: undefined,
      };
    case StoryConstants.Steps.Paris.RECHERCHE_FREQUENCE:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeFrequenciesParis,
        header: "Fréquences",
        subheader: "Sélectionner une fréquence à déchiffrer",
        isCentered: false,
      };
    case StoryConstants.Steps.Paris.GO_SQUARE:
      return {
        ...baseInfos,
        minLength: 3,
        header: "Le Square",
        subheader: "Trouvez le square mentionné dans le message",
        placeholder: "Square",
      };
    case StoryConstants.Steps.Paris.ON_SQUARE:
      return {
        ...baseInfos,
        minLength: 3,
        type: "number",
        header: "Le Square",
        formElement: SixCodesForm,
        subheader: "Trouvez les six codes",
        placeholder: undefined,
      };
    case StoryConstants.Steps.Paris.WAY_BOLIVAR:
    case StoryConstants.Steps.Paris.WAY_EQUERRE:
    case StoryConstants.Steps.Paris.WAY_REBEVAL:
    case StoryConstants.Steps.Paris.WAY_LAUZIN:
    case StoryConstants.Steps.Paris.WAY_DUNES:
      return {
        ...baseInfos,
        minLength: 3,
        header: "La Piste",
        formElement: CodeFormLinks,
        subheader: "Trouvez la prochaine rue à emprunter",
        placeholder: "Nom de rue",
        links: [
          {
            to: `/document/fichier_${
              stepIdx - StoryConstants.Steps.Paris.WAY_BOLIVAR + 1
            }`,
            str: `Fichier ${
              stepIdx - StoryConstants.Steps.Paris.WAY_BOLIVAR + 1
            }`,
          },
        ],
      };
    case StoryConstants.Steps.Paris.FONTAINE:
      return {
        ...baseInfos,
        type: "number",
        formElement: CodeParisStatue,
        subheader: "Trouvez le code du septième fichier",
      };
    case StoryConstants.Steps.Paris.STAIRS_MESSAGES:
      return {
        ...baseInfos,
        type: "text",
        rows: 7,
        formElement: CodeFormFileToFill,
        subheader: undefined,
      };

    case StoryConstants.Steps.Paris.RECHERCHE_BANC:
      return {
        ...baseInfos,
        type: "text",
        formElement: CodeFormParisFinal,
        header: "Trouver le lieu de rendez-vous",
        subheader: "Le message ne suffit peut-être pas",
        placeholder: "Lieu du rendez-vous",
      };
  }

  return undefined;
}
