import FileImg from "../../../imgs/file.svg";
import GeoImg from "../../../imgs/geo.svg";
import CameraImg from "../../../imgs/camera.svg";
import ChatImg from "../../../imgs/chats_dots.svg";
import LockImg from "../../../imgs/lock.svg";
import WaveImg from "../../../imgs/wave.svg";
import SmsImg from "../../../imgs/sms-organizer.svg";
import FilmImg from "../../../imgs/film.svg";
import WalkImg from "../../../imgs/walk.svg";
import SecretAppImg from "../../../imgs/secret-question.svg";
import PlanetImg from "../../../imgs/planet_minify.svg";

export type AppItem = {
  img: string;
  alt: string;
  text: string;
  application: string;
  disabled?: boolean;
};

export const AllAppItems: { [name: string]: AppItem } = {
  Documents: {
    img: FileImg,
    alt: "Dossier",
    text: "Documents",
    application: "documents",
  },
  Camera: {
    img: CameraImg,
    alt: "Appareil photo",
    text: "Caméra",
    application: "camera",
  },
  Chat: {
    img: ChatImg,
    alt: "Phylactère",
    text: "Messages",
    application: "messages",
  },
  Lock: {
    img: LockImg,
    alt: "Cadenas",
    text: "Code",
    application: "code",
  },
  LockSms: {
    img: SmsImg,
    alt: "Bulle conversation",
    text: "Echanges SMS",
    application: "code",
  },
  LastSms: {
    img: SmsImg,
    alt: "Bulle conversation",
    text: "Dernier SMS",
    application: "code",
  },
  WalkCode: {
    img: WalkImg,
    alt: "Personne qui marche",
    text: "Piste",
    application: "code",
  },
  FrequencyCode: {
    img: WaveImg,
    alt: "Ondes Sinusoïdales",
    text: "Fréquences",
    application: "code",
  },
  FrequencyStandalone: {
    img: WaveImg,
    alt: "Ondes Sinusoïdales",
    text: "Fréquences",
    application: "frequencies",
  },
  FilmCredits: {
    img: FilmImg,
    alt: "Pellicule Cinéma",
    text: "Crédits",
    application: "credits",
  },
  PointAlpha: {
    img: GeoImg,
    alt: "Marque de localisation",
    text: "Point ALPHA",
    application: "code",
  },
  SecretApp: {
    img: SecretAppImg,
    alt: "Point d'interrogation",
    text: "Application Secrète",
    application: "code",
  },
  FinalApp: {
    img: PlanetImg,
    alt: "Planète",
    text: "final.exe",
    application: "final",
  },
};
