import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";
import { ToulouseTextsMessageFontaine } from "../../code/code_types/CodeToulouseFontaine";

export function ToulouseMessage2Fontaine() {
  return (
    <BaseMessage
      title="Message &nbsp;de &nbsp;la &nbsp;fontaine"
      isMonospace={true}
    >
      {ToulouseTextsMessageFontaine.map((t) => (
        <p>{t}</p>
      ))}
    </BaseMessage>
  );
}
