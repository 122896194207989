import { CodeFormProps } from "../CodeFormInfos";
import { Expand } from "../../../parts/Expand";

export function CodeToulouseLastSms(p: CodeFormProps) {
  return (
    <>
      <div className="rendez-vous">
        <div className="message-app no-expand">
          <p
            className="company message-in message-short stolen"
            style={{
              maxWidth: "100%",
              padding: "1rem 2rem",
              lineHeight: "110%",
              borderBottomLeftRadius: "0",
            }}
          >
            Si il y a un problème, suis ma piste. <br />
            Quand tu arrives au bout du Crucifix, monte sur le pont. <br />
            Quand tu y seras, ouvre 140 carreaux et cherche une façade très
            centrale. <br />
            <br />
            Fais vite, Henri.
          </p>
        </div>
      </div>
      <Expand />
    </>
  );
}
