import { useSelector } from "react-redux";
import { LinkInfosWithElement } from "../LinkListPage";
import { StoryConstants } from "../../shared/StoryConstants";
import { RootState } from "../../redux/ReduxStore";
import { getLyon1Messages } from "./perCity/MessagesLyon1";
import { getToulouse1Messages } from "./perCity/MessageToulouse1";
import { getParis1Messages } from "./perCity/MessagesParis1";

export function useMessagesArray(): LinkInfosWithElement[] | undefined {
  const { city, storyIdx, stepIdx } = useSelector((state: RootState) => {
    return {
      city: state.status.status?.story?.city,
      storyIdx: state.status.status?.story?.index,
      stepIdx: state.status.status?.step?.index,
    };
  });

  if (city === undefined || storyIdx === undefined || stepIdx === undefined) {
    return undefined;
  }

  if (
    city === StoryConstants.City.LYON &&
    storyIdx === StoryConstants.Story.FIRST_MISSION
  ) {
    return getLyon1Messages(stepIdx);
  } else if (
    city === StoryConstants.City.TOULOUSE &&
    storyIdx === StoryConstants.Story.FIRST_MISSION
  ) {
    return getToulouse1Messages(stepIdx);
  } else if (
    city === StoryConstants.City.PARIS &&
    storyIdx === StoryConstants.Story.FIRST_MISSION
  ) {
    return getParis1Messages(stepIdx);
  } else {
    console.error("Histoire inconnue");
    return [];
  }
}
