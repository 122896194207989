import "../../styles/A4.scss";
import "../../styles/A4unset.scss";
import "../../commons/styleMission.scss";
import QRCode from "qrcode.react";
import { useStoryStatus } from "../../../redux/status/StatusSlice";
import { DocumentPage } from "../../DocumentsElements";
import Config from "../../../Config";
import { RuleBox } from "../../commons/RuleBox";

export function ToulouseMissionOrder() {
  const storyStatus = useStoryStatus();
  const code: string | undefined = storyStatus?.code;

  if (!code) {
    return <></>;
  }

  const contactMethod = storyStatus?.giftFrom ? (
    <p>
      Cette mission vous est offerte par <b>{storyStatus.giftFrom}</b>. Ne
      décevez pas la confiance placée en vous.
    </p>
  ) : (
    <p>Votre profil a suscité notre intérêt.</p>
  );
  //
  const city = "Toulouse";
  return (
    <DocumentPage extraClass="mission-order">
      <div className="header">
        <h1>
          <span className="strong">La Compagnie</span>Ordre de mission
        </h1>
      </div>
      <div className="main">
        <p className="">
          Bonjour,
          <br />
          nous sommes la Compagnie.
        </p>
        {contactMethod}
        <p>
          Notre agence se charge de missions d'informations et de protections.
          Nous sommes respectés pour notre discrétion et notre excellence mais
          nous sommes sans nouvelles d'un de nos agents. Nous pensons que vous
          avez les capacités pour reprendre son enquête et le retrouver.
        </p>
        <p className="strong">
          Ne poursuivez la lecture de ce document que si vous êtes intéressé.
        </p>
        <p>
          Il y a quelques semaines, nous avons reçu un tuyau d'une dénommée «
          Dani » nous annonçant qu'un informateur de grande valeur se cachait
          dans {city}. L'agent HENRI avait alors été missionné pour s'y rendre
          et établir le contact.
        </p>
        <p>
          Peu de temps après, notre agent a reçu un message vocal que nous avons
          pu intercepter. Le lendemain, il disparaissait.
        </p>
        <p>
          L'appel venait d'un téléphone prépayé mais nous vous en joignons la
          retranscription. Ce message semble indiquer un lieu de rendez-vous,
          c'est sans doute votre meilleure piste pour commencer l'enquête.
        </p>
        <p className="extraSpace">
          En cas de succès, votre travail sera dûment reconnu.
          <br />
        </p>
        <div className="expand" />
        <RuleBox />
        <div className="expand" />
      </div>
      <div className="end">
        <div className="left">
          <p className="tagline">In Abstentia Lucis, Tenebrae Vincunt</p>
          <p className="">La Compagnie</p>
        </div>
        <div className="right text-center">
          <QRCode value={`${Config.mainUrl}/a/${code}`} renderAs="svg" />
          <p style={{ fontSize: "80%" }}>Mission : {code}</p>
        </div>
      </div>
      <div className="footer">
        <p>
          Nous Sommes La Companie · Jeu d'enquêtes postales et locales ·{" "}
          noussommeslacompagnie.fr
        </p>
      </div>
    </DocumentPage>
  );
}
