import { NavigateFunction } from "react-router";
import { StoryConstants } from "../../shared/StoryConstants";
import { StoryMainApi, StoryStatusApi } from "../../shared/StoryElements";
import { CodeFormInfos } from "./CodeFormInfos";
import { CodeFormNormal } from "./code_types/CodeFormNormal";
import { CodeListLyon1 } from "./CodeListLyon1";
import { CodeListToulouse1 } from "./CodeListToulouse1";
import { CodeListParis1 } from "./CodeListParis1";

export function getCodeFormInfos(
  storyStatus: {
    story: StoryMainApi | undefined;
    step: { index: number } | undefined;
  } | null
): CodeFormInfos | undefined {
  var currentInfos: CodeFormInfos | undefined = undefined;
  const baseInfos: CodeFormInfos = {
    isCentered: true,
    type: "text",
    placeholder: "Code/Lieu",
    header: "Avez-vous du nouveau ?",
    subheader: "Veuillez rentrer le code pour la prochaine étape",
    formElement: CodeFormNormal,
  };

  if (storyStatus && storyStatus.step?.index) {
    switch (storyStatus.story?.index) {
      case StoryConstants.Story.FIRST_MISSION:
        if (storyStatus.story?.city === StoryConstants.City.PARIS) {
          currentInfos = CodeListParis1(storyStatus.step?.index, baseInfos);
        } else if (storyStatus.story?.city === StoryConstants.City.LYON) {
          currentInfos = CodeListLyon1(storyStatus.step?.index, baseInfos);
        } else if (storyStatus.story?.city === StoryConstants.City.TOULOUSE) {
          currentInfos = CodeListToulouse1(storyStatus.step?.index, baseInfos);
        }
        break;
    }
  }

  return currentInfos;
}

export function onSuccessNavigation(
  status: StoryStatusApi,
  navigate: NavigateFunction
) {
  if (status.story?.index === StoryConstants.Story.FIRST_MISSION) {
    switch (status.story.city) {
      case StoryConstants.City.PARIS:
        switch (status.step?.index) {
          case StoryConstants.Steps.Paris.PARC_FETE:
            navigate("/home/messages/premierMessage?appear");
            break;
          case StoryConstants.Steps.Paris.GO_BIBLI:
            navigate("/home/messages/appOuverte?appear");
            break;
          case StoryConstants.Steps.Paris.RECHERCHE_FREQUENCE:
            navigate("/home/messages/lesCroquis?appear");
            break;
          case StoryConstants.Steps.Paris.GO_SQUARE:
            navigate("/home/messages/leSquare?appear");
            break;
          case StoryConstants.Steps.Paris.ON_SQUARE:
            navigate("/home/messages/sixCodes?appear");
            break;
          case StoryConstants.Steps.Paris.WAY_BOLIVAR:
            navigate("/home/messages/laPiste?appear");
            break;
          case StoryConstants.Steps.Paris.WAY_EQUERRE:
          case StoryConstants.Steps.Paris.WAY_REBEVAL:
          case StoryConstants.Steps.Paris.WAY_LAUZIN:
          case StoryConstants.Steps.Paris.WAY_DUNES:
            navigate("/home/app/correct");
            break;
          case StoryConstants.Steps.Paris.FONTAINE:
            navigate("/home/messages/dernierFichier?appear");
            break;
          case StoryConstants.Steps.Paris.STAIRS_MESSAGES:
            navigate("/home/messages/donneesCachees?appear");
            break;
          case StoryConstants.Steps.Paris.RECHERCHE_BANC:
            navigate("/home/app/correct");
            break;
          case StoryConstants.Steps.Paris.GAME_END:
            navigate("/home");
            navigate("/home/messages/laFin?appear");
            break;
        }
        break;
      case StoryConstants.City.LYON:
        switch (status.step?.index) {
          case StoryConstants.Steps.Lyon.FIND_STATUE:
            navigate("/home/messages/premierMessage?appear");
            break;
          case StoryConstants.Steps.Lyon.DAME_PERCHEE:
            navigate("/home/messages/secondMessage?appear");
            break;
          case StoryConstants.Steps.Lyon.RENDEZ_VOUS:
            navigate("/home/messages/rendezVous?appear");
            break;
          case StoryConstants.Steps.Lyon.DEVOIR_MUT:
            navigate("/home/messages/cleUsb?appear");
            break;
          case StoryConstants.Steps.Lyon.STARS:
            navigate("/home/messages/stars?appear");
            break;
          case StoryConstants.Steps.Lyon.FIRST_END:
            navigate("/home");
            navigate("/home/messages/laFin?appear");
            break;
        }

        break;
      case StoryConstants.City.TOULOUSE:
        switch (status.step?.index) {
          case StoryConstants.Steps.Toulouse.FIND_FOUNTAIN:
            navigate("/home/messages/premierMessage?appear");
            break;
          case StoryConstants.Steps.Toulouse.TRINITE_TO_ASSEZAT:
            navigate("/home/messages/secondMessage?appear");
            break;
          case StoryConstants.Steps.Toulouse.ECHARPE_TABAC:
            navigate("/home/messages/laCle?appear");
            break;
          case StoryConstants.Steps.Toulouse.LE_PONT:
            navigate("/home/messages/cleOuverte?appear");
            break;
          case StoryConstants.Steps.Toulouse.PISTE_PHOTO:
            navigate("/home/app/correct");
            break;
          case StoryConstants.Steps.Toulouse.FREQUENCES:
            navigate("/home/messages/frequences?appear");
            break;
          case StoryConstants.Steps.Toulouse.RECHERCHE_PASSERELLE:
            navigate("/home/messages/nouvelleFrequence?appear");
            break;
          case StoryConstants.Steps.Toulouse.SQUARE_END:
            navigate("/home/messages/programmeFinal?appear");
            break;
          case StoryConstants.Steps.Toulouse.ALTERNATIVE_PONT:
            navigate("/home/messages/ultimeSms?appear");
            break;
          case StoryConstants.Steps.Toulouse.GAME_END:
            navigate("/home");
            navigate("/home/messages/laFin?appear");
            break;
        }
        break;
    }
  }
}

export const CodeFormsInfos = {
  LA_PLACE: getCodeFormInfos({
    story: {
      city: StoryConstants.City.LYON,
      index: StoryConstants.Story.FIRST_MISSION,
      schedule: null,
    },
    step: {
      index: StoryConstants.Steps.Lyon.LA_PLACE,
    },
  }),
  PARIS_FREQ: getCodeFormInfos({
    story: {
      city: StoryConstants.City.PARIS,
      index: StoryConstants.Story.FIRST_MISSION,
      schedule: null,
    },
    step: {
      index: StoryConstants.Steps.Paris.RECHERCHE_FREQUENCE,
    },
  }),
};
