import { PropsWithChildren, ReactNode } from "react";
import { LinkInfos, LinkInfosWithElement } from "../pages/LinkListPage";
import { StoryStatusApi } from "../shared/StoryElements";
import { StoryConstants } from "../shared/StoryConstants";
import { LyonMissionOrder } from "./lyon_1/1_ordre_mission/LyonMissionOrder";
import { LyonTranscriptionDocument } from "./lyon_1/2_transcription/LyonTranscriptionDocument";
import { LyonMapDocument } from "./lyon_1/3_map/LyonMapDocument";
import { LyonStolenDocument } from "./lyon_1/4_stolen_message/LyonStolenDocument";
import { ToulouseMissionOrder } from "./toulouse_1/1_ordre_mission/ToulouseMissionOrder";
import { ToulouseTranscriptionDocument } from "./toulouse_1/2_transcription/ToulouseTranscriptionDocument";
import { ToulouseMapDocument } from "./toulouse_1/3_map/ToulouseMapDocument";
import { useStoryStatus } from "../redux/status/StatusSlice";
import {
  ToulouseUsbDocumentLight,
  ToulouseUsbDocumentPont,
} from "./toulouse_1/4_usb_files/ToulouseUsbDocument";
import { UsbImageFile } from "./image_file/UsbImageFile";

import toulouseImage1 from "./toulouse_1/5_usb_images/image1.JPG";
import toulouseImage2 from "./toulouse_1/5_usb_images/image2.JPG";
import toulouseImage3 from "./toulouse_1/5_usb_images/image3.JPG";
import toulouseImage4 from "./toulouse_1/5_usb_images/image4.JPG";
import toulouseImage5 from "./toulouse_1/5_usb_images/paperImage.png";

import parisCroquis1 from "./paris_1/4_croquis/paper1.jpg";
import parisCroquis2 from "./paris_1/4_croquis/paper2.jpg";
import parisCroquis3 from "./paris_1/4_croquis/paper3.jpg";
import parisCroquis4 from "./paris_1/4_croquis/paper4.jpg";

import parisFichier1 from "./paris_1/5_piste/piste1.jpg";
import parisFichier2 from "./paris_1/5_piste/piste2.jpg";
import parisFichier3 from "./paris_1/5_piste/piste3.jpg";
import parisFichier4 from "./paris_1/5_piste/piste4.jpg";
import parisFichier5 from "./paris_1/5_piste/piste5.jpg";
import parisFichier6 from "./paris_1/5_piste/piste6.jpg";

import { ParisMissionOrder } from "./paris_1/1_ordre_mission/ParisMissionOrder";
import { ParisTranscriptionDocument } from "./paris_1/2_transcription/ParisTranscriptionDocument";
import { ParisMapDocument } from "./paris_1/3_map/ParisMapDocument";

type CityDocuments = {
  mission_order: ReactNode;
  transcription: ReactNode;
  map: ReactNode;
} & { [s: string]: ReactNode };

export function DocumentPage({
  children,
  extraClass,
}: PropsWithChildren<{ extraClass?: string }>) {
  return (
    <div className="full-page">
      <div className={"a4unset page " + (extraClass ? extraClass : "")}>
        {children}
      </div>
    </div>
  );
}

export const DocumentsInfosList: { [s: string]: LinkInfos } = {
  mission_order: { url: "mission_order", text: "Ordre de Mission" },
  transcription: { url: "transcription", text: "Retranscription Téléphonique" },
  map: { url: "map", text: "Carte de la ville" },
  stolen_message: { url: "stolen_message", text: "Message de DANI à HENRI" },
};

const LyonDocuments: CityDocuments = {
  mission_order: <LyonMissionOrder />,
  transcription: <LyonTranscriptionDocument />,
  map: <LyonMapDocument />,
  stolen_message: <LyonStolenDocument />,
};

const ParisDocuments: CityDocuments = {
  mission_order: <ParisMissionOrder />,
  transcription: <ParisTranscriptionDocument />,
  map: <ParisMapDocument />,
};

const ToulouseDocuments: CityDocuments = {
  mission_order: <ToulouseMissionOrder />,
  transcription: <ToulouseTranscriptionDocument />,
  map: <ToulouseMapDocument />,
};

const ToulouseBridgeDocuments: {
  [s: string]: LinkInfos & { elmt: ReactNode };
} = {
  fichier_1: {
    url: "usb_file1",
    text: "Clé USB - Fichier 1",
    elmt: <ToulouseUsbDocumentPont />,
  },
  img_2_art: {
    url: "usb_file2",
    text: "Clé USB - Fichier 2",
    elmt: (
      <UsbImageFile
        src={toulouseImage1}
        alt={"Fronton de porte"}
        direction="horizontal"
      />
    ),
  },
  img_3_porte: {
    url: "usb_file3",
    text: "Clé USB - Fichier 3",
    elmt: (
      <UsbImageFile
        src={toulouseImage2}
        alt={"Porte blanche"}
        direction="horizontal"
      />
    ),
  },
  img_4_road: {
    url: "usb_file4",
    text: "Clé USB - Fichier 4",
    elmt: (
      <UsbImageFile
        src={toulouseImage3}
        alt={"Mur gris"}
        direction="vertical"
      />
    ),
  },
  img_5_house: {
    url: "usb_file5",
    text: "Clé USB - Fichier 5",
    elmt: (
      <UsbImageFile src={toulouseImage4} alt={"Façade"} direction="vertical" />
    ),
  },
  img_6_fish: {
    url: "usb_file6",
    text: "Clé USB - Fichier 6",
    elmt: (
      <UsbImageFile
        src={toulouseImage5}
        alt={"Papier Manuscrit"}
        direction="fit"
      />
    ),
  },
  fichier_7: {
    url: "usb_file7",
    text: "Clé USB - Fichier 7",
    elmt: <ToulouseUsbDocumentLight />,
  },
};

const ParisCroquis: {
  [s: string]: LinkInfos & { elmt: ReactNode };
} = {
  croquis_1: {
    url: "croquis_1",
    text: "Application - Croquis 1",
    elmt: (
      <UsbImageFile
        src={parisCroquis1}
        alt={"Schéma de porte"}
        direction="fit"
      />
    ),
  },
  croquis_2: {
    url: "croquis_2",
    text: "Application - Croquis 2",
    elmt: (
      <UsbImageFile
        src={parisCroquis2}
        alt={"Schéma avec six chiffres"}
        direction="fit"
      />
    ),
  },
  croquis_3: {
    url: "croquis_3",
    text: "Application - Croquis 3",
    elmt: (
      <UsbImageFile
        src={parisCroquis3}
        alt={"Poisson avec quatre croix vertes"}
        direction="fit"
      />
    ),
  },
  croquis_4: {
    url: "croquis_4",
    text: "Application - Croquis 4",
    elmt: (
      <UsbImageFile
        src={parisCroquis4}
        alt={"Dessin étrange avec une croix verte"}
        direction="fit"
      />
    ),
  },
};

const ParisFichiers: {
  [s: string]: LinkInfos & { elmt: ReactNode };
} = {
  fichier_1: {
    url: "fichier_1",
    text: "Fichier 1",
    elmt: (
      <UsbImageFile
        src={parisFichier1}
        alt={"une colonne Morris"}
        direction="fit"
      />
    ),
  },
  fichier_2: {
    url: "fichier_2",
    text: "Fichier 2",
    elmt: (
      <UsbImageFile
        src={parisFichier2}
        alt={"une rue descendante"}
        direction="fit"
      />
    ),
  },
  fichier_3: {
    url: "fichier_3",
    text: "Fichier 3",
    elmt: (
      <UsbImageFile
        src={parisFichier3}
        alt={"une intersection"}
        direction="fit"
      />
    ),
  },
  fichier_4: {
    url: "fichier_4",
    text: "Fichier 4",
    elmt: (
      <UsbImageFile
        src={parisFichier4}
        alt={"une rue bordée d'immeuble"}
        direction="fit"
      />
    ),
  },
  fichier_5: {
    url: "fichier_5",
    text: "Fichier 5",
    elmt: (
      <UsbImageFile
        src={parisFichier5}
        alt={"une rue montante"}
        direction="fit"
      />
    ),
  },
  fichier_6: {
    url: "fichier_6",
    text: "Fichier 6",
    elmt: (
      <UsbImageFile
        src={parisFichier6}
        alt={"une fontaine et une rue"}
        direction="fit"
      />
    ),
  },
};

export function useDocumentsArray(): LinkInfosWithElement[] | undefined {
  const status = useStoryStatus();

  return useCustomDocumentsArray(status);
}

export function useCustomDocumentsArray(
  status: StoryStatusApi | null | undefined
) {
  const { city, storyIdx, stepIdx } = {
    city: status?.story?.city,
    storyIdx: status?.story?.index,
    stepIdx: status?.step?.index,
  };

  if (city === undefined || storyIdx === undefined || stepIdx === undefined) {
    return undefined;
  }

  var cityDocuments;
  if (city === StoryConstants.City.LYON) {
    cityDocuments = LyonDocuments;
  } else if (city === StoryConstants.City.TOULOUSE) {
    cityDocuments = ToulouseDocuments;
  } else if (city === StoryConstants.City.PARIS) {
    cityDocuments = ParisDocuments;
  } else {
    console.error("City not recognized in Documents");
    return undefined;
  }

  const toReturn: LinkInfosWithElement[] = [];
  toReturn.push({
    infos: DocumentsInfosList.mission_order,
    element: cityDocuments.mission_order,
  });
  toReturn.push({
    infos: DocumentsInfosList.transcription,
    element: cityDocuments.transcription,
  });
  toReturn.push({
    infos: DocumentsInfosList.map,
    element: cityDocuments.map,
  });

  if (storyIdx === StoryConstants.Story.FIRST_MISSION) {
    switch (city) {
      case StoryConstants.City.LYON:
        if (stepIdx >= StoryConstants.Steps.Lyon.DEVOIR_MUT) {
          toReturn.push({
            infos: DocumentsInfosList.stolen_message,
            element: LyonDocuments.stolen_message,
          });
        }
        break;
      case StoryConstants.City.TOULOUSE:
        if (stepIdx >= StoryConstants.Steps.Toulouse.LE_PONT) {
          for (let x in ToulouseBridgeDocuments) {
            toReturn.push({
              infos: ToulouseBridgeDocuments[x],
              element: ToulouseBridgeDocuments[x].elmt,
            });
          }
        }
        break;
      case StoryConstants.City.PARIS:
        if (stepIdx >= StoryConstants.Steps.Paris.RECHERCHE_FREQUENCE) {
          for (let x in ParisCroquis) {
            toReturn.push({
              infos: ParisCroquis[x],
              element: ParisCroquis[x].elmt,
            });
          }
        }
        if (stepIdx >= StoryConstants.Steps.Paris.WAY_BOLIVAR) {
          for (let x in ParisFichiers) {
            toReturn.push({
              infos: ParisFichiers[x],
              element: ParisFichiers[x].elmt,
            });
          }
        }
        break;
    }
  }

  return toReturn;
}
