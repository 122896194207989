import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ParisMessage2AppOuverte() {
  return (
    <BaseMessage title="Application Ouverte">
      <p>Votre talent nous étonne.</p>
      <p>
        Les éléments que vous nous avez indiqués ont bien ouvert l'application.
      </p>
      <p>
        Nous tombons sur un étrange message et il semblerait qu'il faille un
        code à quatre chiffre pour réellement accéder à son contenu.
      </p>
      <p>
        Nous supposons que cet "indicateur" cherchait à isoler notre agent avant
        de l'emmener dans un guet-apens. Le message indique sans doute les
        routes à prendre depuis la Place des Fêtes.{" "}
      </p>
      <p>
        Nous détectons que notre agent s'est d'abord dirigé vers la{" "}
        <b style={{ paddingLeft: "0.4em" }}>Rue des Solitaires</b>, cela
        pourrait correspondre au début du message.
      </p>
      <p>Suivez la piste et elle devrait vous conduire au code.</p>
      <p>
        <br />
      </p>
      <p>
        Transmettez-le dès que possible,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
