import { StoryConstants } from "../../../shared/StoryConstants";
import { LinkInfosWithElement } from "../../LinkListPage";
import { ToulouseMessage10LaFin } from "../pages_toulouse/ToulouseMessage10_Lafin";
import { ToulouseMessage1Alpha } from "../pages_toulouse/ToulouseMessage1_Alpha";
import { ToulouseMessage2Fontaine } from "../pages_toulouse/ToulouseMessage2_Fontaine";
import { ToulouseMessage3GoAssezat } from "../pages_toulouse/ToulouseMessage3_GoAssezat";
import { ToulouseMessage4Hotel } from "../pages_toulouse/ToulouseMessage4_MessageHotel";
import { ToulouseMessage5CleUsb } from "../pages_toulouse/ToulouseMessage5_CleUsb";
import { ToulouseMessage6CleDeverrouillee } from "../pages_toulouse/ToulouseMessage6_OuvertureCle";
import { ToulouseMessage7Frequences } from "../pages_toulouse/ToulouseMessage7_Frequences";
import { ToulouseMessage8NouvelleFrequence } from "../pages_toulouse/ToulouseMessage8_NouvelleFrequence";
import { ToulouseMessage9ProgrammeFinal } from "../pages_toulouse/ToulouseMessage9_ProgrammeFinal";
import { ToulouseMessageB9Alternative } from "../pages_toulouse/ToulouseMessageB9_Alternative";

export function getToulouse1Messages(stepIdx: number): LinkInfosWithElement[] {
  const toReturn: LinkInfosWithElement[] = [];

  if (stepIdx >= StoryConstants.Steps.Toulouse.FIND_FOUNTAIN) {
    toReturn.push({
      infos: {
        url: "premierMessage",
        text: "Premier Message",
      },
      element: <ToulouseMessage1Alpha />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Toulouse.TRINITE_TO_ASSEZAT) {
    toReturn.push({
      infos: {
        url: "messageFontaine",
        text: "Message de la Fontaine",
      },
      element: <ToulouseMessage2Fontaine />,
    });

    toReturn.push({
      infos: {
        url: "secondMessage",
        text: "Second Message",
      },
      element: <ToulouseMessage3GoAssezat />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Toulouse.ECHARPE_TABAC) {
    toReturn.push({
      infos: {
        url: "messageHotel",
        text: "Message Hôtel",
      },
      element: <ToulouseMessage4Hotel />,
    });

    toReturn.push({
      infos: {
        url: "laCle",
        text: "La Clé",
      },
      element: <ToulouseMessage5CleUsb />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Toulouse.LE_PONT) {
    toReturn.push({
      infos: {
        url: "cleOuverte",
        text: "Clé Déverrouillée",
      },
      element: <ToulouseMessage6CleDeverrouillee />,
    });
  }

  if (stepIdx >= StoryConstants.Steps.Toulouse.FREQUENCES) {
    toReturn.push({
      infos: {
        url: "frequences",
        text: "Fréquences",
      },
      element: <ToulouseMessage7Frequences />,
    });
  }

  if (stepIdx < StoryConstants.Steps.Toulouse.ALTERNATIVE_PONT) {
    if (stepIdx >= StoryConstants.Steps.Toulouse.RECHERCHE_PASSERELLE) {
      toReturn.push({
        infos: {
          url: "nouvelleFrequence",
          text: "Nouvelle Fréquence",
        },
        element: <ToulouseMessage8NouvelleFrequence />,
      });
    }

    if (stepIdx >= StoryConstants.Steps.Toulouse.SQUARE_END) {
      toReturn.push({
        infos: {
          url: "programmeFinal",
          text: "Programme Final",
        },
        element: <ToulouseMessage9ProgrammeFinal />,
      });
    }

    if (stepIdx > StoryConstants.Steps.Toulouse.SQUARE_END) {
      toReturn.push({
        infos: {
          url: "laFin",
          text: "Remerciements",
        },
        element: <ToulouseMessage10LaFin />,
      });
    }
  }

  if (stepIdx >= StoryConstants.Steps.Toulouse.ALTERNATIVE_PONT) {
    toReturn.push({
      infos: {
        url: "ultimeSms",
        text: "Ultime SMS",
      },
      element: <ToulouseMessageB9Alternative />,
    });
  }

  return toReturn;
}
