import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ParisMessage5SixCodes() {
  return (
    <BaseMessage title="Six Codes">
      <p>Votre raisonnement semble correct.</p>
      <p>
        Pendant que vous vous promeniez, nous avons identifié que les six
        premiers fichiers semblent partager le même système de chiffrement.
      </p>
      <p>
        Nous n'avons plus d'autre piste. Nous comptons sur vous pour trouver les
        six codes d'un coup et nous permettre de déchiffrer ces documents.
      </p>
      <p>La clé est sans doute proche.</p>
      <p>
        <br />
      </p>
      <p>
        Pensez vite,
        <br />
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
