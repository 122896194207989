import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ToulouseMessage10LaFin() {
  return (
    <BaseMessage title="Remerciements">
      <p>Félicitations, vous avez réussi.</p>
      <p>Ainsi ils s'étaient donnés rendez-vous vers les espaces Bazacle.</p>
      <p>
        Nous avons également pu récupérer de nouvelles informations essentielles
        pour nous sur la clé USB.
      </p>
      <p>
        Nos équipes vont se charger de suivre les signaux qu'ils ont pu y
        laisser.
      </p>
      <p>
        Nous ferons de notre mieux, mais si HENRI reste introuvable, nous
        devrons nous concentrer sur cette prétendue source.
      </p>
      <p>
        <br />
      </p>
      <p>
        Merci de nous avoir aidé dans cette enquête. Nous vous recontacterons si
        nécessaire.
      </p>
      <p>
        <br />
      </p>
      <p>
        <span className="italic bold">La Compagnie</span>
      </p>
    </BaseMessage>
  );
}
