import { TIME_BETWEEN_CLUES_MS } from "../../../../shared/StoryElements";

export const CLUE_POPUP_NEXT_BUTTON_COLORS = { active: "#bbb", inactive: "#888" };

export function getTimePercent(
    current: Date,
    previous: Date,
    duration = TIME_BETWEEN_CLUES_MS
) {
    return Math.trunc(
        (100 * (current.getTime() - previous.getTime())) / duration
    );
}

export function getClueButtonPercentedBackground(
    colors: { active: string; inactive: string },
    percent: number
) {
    if (percent > 0 && percent < 1) {
        percent *= 100;
    }

    return {
        backgroundImage: `linear-gradient(0.25turn, ${colors.active} ${percent}%, ${colors.inactive} ${percent}%)`,
    };
}