import { useState } from "react";
import { CodeFormProps } from "../CodeFormInfos";
import { ParisTextsMessage } from "./CodeParisFontaine";

export function CodeFormParisFinal(p: CodeFormProps) {
  const [currentCode, setCurrentCode] = useState<string>("");

  const finalText =
    ParisTextsMessage.concat(
      "GL",
      "...",
      "OK.",
      "Montez et suivez GL.",
      "Ceci est mon dernier message."
    ).join("\n") + "\n";

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        p.checkCode(currentCode);
      }}
      style={{ marginBottom: "15vh" }}
    >
      {p.errorMessage ? (
        <h2 className="is-family-monospace login-tooltip is-nok">
          {p.errorMessage.split("\n").map((elmt, idx) => (
            <span key={idx}>
              {elmt}
              <br />
            </span>
          ))}
        </h2>
      ) : (
        <></>
      )}
      <div className="field code-app-field">
        <div className="control">
          <input
            className="input is-family-monospace"
            name="code"
            value={currentCode}
            onChange={(e) => setCurrentCode(e.target.value)}
            {...p.formInfos}
          />
        </div>
        <div className="control">
          <button className="button is-fullwidth is-family-monospace is-active">
            VALIDER
          </button>
        </div>
        <div className="bottom-part">
          <h2>Message</h2>
          <div>
            <textarea
              className="input is-family-monospace"
              name="code"
              value={finalText}
              rows={10}
              style={{ height: "unset" }}
              onFocus={(args) => {
                const target = args.target;
                target.selectionStart = target.selectionEnd =
                  target.value.length;
              }}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
