import "../MessagesBase.scss";
import { BaseMessage } from "../BaseMessage";

export function ToulouseMessage9ProgrammeFinal() {
  return (
    <BaseMessage title="Programme Final">
      <p>Vous nous surprenez,</p>
      <p>
        Il semblerait que ce nouveau message vous permette enfin d'ouvrir
        l'application trouvée sur la clé USB.
      </p>
      <p>
        Le code pour l'ouvrir est sans doute lié à leur lieu de rendez-vous.
      </p>
      <p>
        <br></br>
        Ne lâchez pas la piste. Nous les tenons !<br />
        <span className="italic bold">La Compagnie</span>
        <br />
        <br />
      </p>
      <p style={{ fontSize: "90%" }}>
        <strong>Information importante : </strong>
        <em>
          La suite de l'aventure contient une ou plusieurs sections parfois
          fermées au public. Un itinéraire alternatif est disponible dans le
          bouton "Aide" en haut à droite.
        </em>
      </p>
    </BaseMessage>
  );
}
